export const NEW = 'NEW'
export const REVIEW = 'REVIEW'
export const WAITING_FOR_PAYMENT = 'WAITING_FOR_PAYMENT'
export const DONE_BY_PARTNER = 'DONE_BY_PARTNER'
export const IN_PROGRESS = 'IN_PROGRESS'
export const DONE = 'DONE'
export const CANCELED = 'CANCELED'
export const PAID = 'PAID'

export default {
  NEW,
  REVIEW,
  WAITING_FOR_PAYMENT,
  IN_PROGRESS,
  DONE,
  DONE_BY_PARTNER,
  CANCELED,
  PAID
}
