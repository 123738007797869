import { onSnapshot, query, collection } from 'firebase/firestore'
import _ from 'lodash'

import { db } from 'controllers/db'
import store from 'model/store'
import { addListener } from 'controllers/listeners'
import { receiveItems } from 'model/actions/itemsAC'
import { ItemT } from 'types/model'

export const fetchItems = () => {
  try {
    const q = query(collection(db, 'furnitures'))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res = {}
        sn.forEach(doc => {
          const p = doc.data() as ItemT
          if (p && p.supplierId) {
            _.set(res, doc.id, p)
          }
        })
        store.dispatch(receiveItems(res))
      },
      err => {
        console.log(`fetchItems error: ${err}`)
      }
    )
    addListener('items', unsubscribe)
  } catch (e) {
    console.log('fetchItems error', e)
  }
}
