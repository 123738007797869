import _ from 'lodash'
import { useEffect, useRef, FunctionComponent } from 'react'
import { MathUtils, Vector3 } from 'three'
import { MpSdk } from 'shared/bundle/sdk'
// import { hemisphereLightType } from 'shared/components/matterport/MHemisphereLight'
import { customPointLightType } from 'shared/components/matterport/MPointLight'
// import { roomEnvironmentType } from 'shared/components/matterport/MRoomEnvironment'

type Props = {
  sdk: MpSdk
}

const PointLightOnCamera: FunctionComponent<Props> = ({ sdk }: Props) => {
  const nodeRef = useRef<MpSdk.Scene.INode | null>(null)
  const node2Ref = useRef<MpSdk.Scene.INode | null>(null)
  const subscriptionRef = useRef<MpSdk.ISubscription | null>(null)

  useEffect(() => {
    const run = async () => {
      if (_.isEmpty(nodeRef.current)) {
        const sceneObjs: Array<MpSdk.Scene.IObject> =
          await sdk.Scene.createObjects(1)
        const node = sceneObjs[0].addNode()
        const node2 = sceneObjs[0].addNode()
        nodeRef.current = node
        node2Ref.current = node2
        const initial = {
          enabled: true,
          color: 0xf7f7f0,
          intensity: 1,
          distance: 20,
          decay: 2,
          // debug: true,
          castShadow: true,
          shadowMapSize: {
            width: 4096,
            height: 4096
          }
        }

        const initalWithNoShadow = {
          ...initial,
          distance: 20,
          decay: 2,
          intensity: 0.5,
          castShadow: false
        }
        const plComponent = node.addComponent(customPointLightType, initial)
        // node2.addComponent(roomEnvironmentType)
        const plComponent2 = node.addComponent(
          customPointLightType,
          initalWithNoShadow
        )
        // const plComponent3 = node.addComponent(
        //   customPointLightType,
        //   initalWithNoShadow
        // )
        // const plComponent4 = node.addComponent(
        //   customPointLightType,
        //   initalWithNoShadow
        // )

        // const hsLight = node2.addComponent(hemisphereLightType, {
        //   intensity: 3,
        //   debug: false
        // })

        node.start()

        const unsubscribe = sdk.Camera.pose.subscribe(pose => {
          // console.log('Camera.pose', pose)

          const radAngle = MathUtils.degToRad(pose.rotation.y)
          const v: Vector3 = new Vector3(0, 0, -1)
          v.applyAxisAngle(new Vector3(0, 1, 0), radAngle)
          const v2: Vector3 = new Vector3(0, 0, -1)
          v2.applyAxisAngle(new Vector3(0, 1, 0), radAngle + Math.PI / 8)
          const v4: Vector3 = new Vector3(0, 0, -1)
          v4.applyAxisAngle(new Vector3(0, 1, 0), radAngle - Math.PI / 4)
          if (plComponent.inputs && plComponent.inputs.position) {
            plComponent.inputs.position = {
              x: pose.position.x,
              y: pose.position.y + 10,
              z: pose.position.z
            }
          }

          if (plComponent2.inputs && plComponent2.inputs.position) {
            plComponent2.inputs.position = {
              x: pose.position.x + v2.x,
              y: pose.position.y + 0.6,
              z: pose.position.z + v2.z
            }
          }

          // if (plComponent3.inputs && plComponent3.inputs.position) {
          //   plComponent3.inputs.position = {
          //     x: pose.position.x + v.x * 4,
          //     y: pose.position.y + 0.5,
          //     z: pose.position.z + v.z * 4
          //   }
          // }
          // if (plComponent4.inputs && plComponent4.inputs.position) {
          //   plComponent4.inputs.position = {
          //     x: pose.position.x + v4.x * 1.5,
          //     y: pose.position.y,
          //     z: pose.position.z + v4.z * 1.5
          //   }
          // }

          // if (hsLight.inputs && hsLight.inputs.position) {
          //   hsLight.inputs.position = [
          //     pose.position.x + v.x * 1.5,
          //     pose.position.y,
          //     pose.position.z + v.z * 1.5
          //   ]
          // }
        })

        subscriptionRef.current = unsubscribe
      }
    }
    run()
    return () => {
      const node = nodeRef.current
      node?.stop()
      const node2 = node2Ref.current
      node2?.stop()
      if (subscriptionRef.current) {
        const s: MpSdk.ISubscription = subscriptionRef.current
        s.cancel()
      }
    }
  }, [])

  return null
}

export default PointLightOnCamera
