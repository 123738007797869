import * as types from 'model/actionTypes'
import { SettingsT } from 'shared/types/model'
import { AnyAction } from 'redux'

const initialState: SettingsT | null = null

export default function (
  state = initialState,
  action: { type: string; settings: SettingsT } | AnyAction
) {
  switch (action.type) {
    case types.RECEIVE_SETTINGS:
      return action.settings
    case types.CLEAR:
    case types.LOGOUT:
      return initialState
    default:
      return state
  }
}
