import { FC } from 'react'
import { Flex, Button } from '@chakra-ui/react'

import RightPanelWrapper from 'shared/components/RightPanelWrapper'

type Props = {
  onCancel: () => void
}

const AddingItemPanel: FC<Props> = ({ onCancel }) => {
  return (
    <RightPanelWrapper show width={200} fullHeight={false} bg='transparent'>
      <Flex p={2} flex={1} maxH='full' direction='column'>
        <Button colorScheme={'blue'} onClick={() => onCancel()}>
          Cancel
        </Button>
      </Flex>
    </RightPanelWrapper>
  )
}

export default AddingItemPanel
