import { useMemo } from 'react'
import _ from 'lodash'
import { Flex, Text, Button, Tooltip } from '@chakra-ui/react'
import moment from 'moment'
import numeral from 'numeral'
import { useSelector } from 'model/hooks'
import BaseTable from 'react-base-table'
import { useMeasure } from 'react-use'
import { DictT, OrderT, TourModelT, TourT } from 'shared/types/model'
import OrderStatusTag from 'components/OrderStatusTag'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/pro-regular-svg-icons'
import { useNavigate } from 'react-router-dom'
import { getIsUpstagerTeam } from 'model/selectors/base'
import { getOrderStatus } from 'utils/orders'

type RowT = {
  id: string
  name: string
  email: string
  timestamp: number
  address: string
  design: string
  total: number
  quantity: number
  status: string
  deliveryDate?: string
  partner: string
}

const OrdersContent = () => {
  const orders: DictT<OrderT> = useSelector(state => state.orders)
  const tourModels = useSelector(state => state.tourModels)
  const tours = useSelector(state => state.tours)
  const items = useSelector(state => state.items)
  const isUpstagerTeam = useSelector(getIsUpstagerTeam)
  const partners = useSelector(state => state.partners)
  const [containerRef, { width, height }] = useMeasure<HTMLDivElement>()
  const navigate = useNavigate()

  const data = useMemo(() => {
    if (tourModels) {
      const rows = _.map(orders, (o: OrderT) => {
        const tm: TourModelT = tourModels[o.tourModelId]
        const address = _.get(tm, 'address.name', '')
        const tour: TourT | null = o.tourId ? tours[o.tourId] : null
        const itemsIds = _.uniq(_.map(o.items, ts => ts.itemId))
        const quantity = _.sum(_.values(o.quantities))
        const total = _.sum(
          _.map(
            itemsIds,
            itemId =>
              _.get(items, [itemId, 'price'], 0) *
              _.get(o.quantities, itemId, 0)
          )
        )
        const row: RowT = {
          id: o.id,
          name: o.name,
          email: o.email,
          timestamp: o.timestamp,
          address,
          design: tour ? tour.name : '-',
          total,
          quantity,
          status: getOrderStatus(o, isUpstagerTeam),
          deliveryDate: o.upstagerDeliveryDate || o.partnerDeliveryDate,
          partner: _.get(partners, [o.partnerId, 'name'], '')
        }
        return row
      })
      return _.orderBy(rows, ['timestamp'], 'desc')
    } else {
      return []
    }
  }, [orders])

  const columns = [
    {
      flexGrow: 0.6,
      key: 'id',
      dataKey: 'id',
      width: 0,
      title: 'ID',
      cellRenderer: ({ cellData }: { cellData: string }) => (
        <Tooltip label={cellData}>
          <Button
            variant={'link'}
            onClick={() => navigate(`/orders/${cellData}`)}
            leftIcon={<FontAwesomeIcon icon={faEye} />}
            size='xs'
          >
            {_.truncate(cellData, { length: 10 })}
          </Button>
        </Tooltip>
      )
    },
    {
      flexGrow: 1,
      key: 'name',
      dataKey: 'name',
      width: 0,
      title: 'Name',
      cellRenderer: ({ cellData }: { cellData: string }) => (
        <Text fontSize={'xs'}>{cellData}</Text>
      )
    },
    {
      flexGrow: 1,
      key: 'email',
      dataKey: 'email',
      width: 0,
      title: 'Email',
      cellRenderer: ({ cellData }: { cellData: string }) => (
        <Text fontSize={'xs'}>{cellData}</Text>
      )
    },
    ...(isUpstagerTeam
      ? [
          {
            flexGrow: 1,
            key: 'partner',
            dataKey: 'partner',
            width: 0,
            title: 'Partner',
            cellRenderer: ({ cellData }: { cellData: string }) => (
              <Text fontSize={'xs'}>{cellData}</Text>
            )
          }
        ]
      : []),
    {
      flexGrow: 1,
      key: 'timestamp',
      dataKey: 'timestamp',
      width: 0,
      title: 'Created At',
      cellRenderer: ({ cellData }: { cellData: number }) => (
        <Text fontSize={'xs'}>{moment(cellData).format('lll')}</Text>
      )
    },
    {
      flexGrow: 1,
      key: 'address',
      dataKey: 'address',
      width: 0,
      title: 'Address',
      cellRenderer: ({ cellData }: { cellData: string }) => (
        <Text fontSize={'xs'}>{cellData}</Text>
      )
    },
    {
      flexGrow: 1,
      key: 'design',
      dataKey: 'design',
      width: 0,
      title: 'Design',
      cellRenderer: ({ cellData }: { cellData: string }) => (
        <Text fontSize={'xs'}>{cellData}</Text>
      )
    },
    {
      flexGrow: 0.6,
      key: 'total',
      dataKey: 'total',
      width: 0,
      title: 'Order Total',
      cellRenderer: ({ cellData }: { cellData: string }) => (
        <Text fontSize={'xs'}>{numeral(cellData).format('$0,0.00')}</Text>
      )
    },
    {
      flexGrow: 0.6,
      key: 'amount',
      dataKey: 'quantity',
      width: 0,
      title: '#Items',
      cellRenderer: ({ cellData }: { cellData: string }) => (
        <Text fontSize={'xs'}>{cellData}</Text>
      )
    },
    {
      flexGrow: 0.8,
      key: 'deliveryDate',
      dataKey: 'deliveryDate',
      width: 0,
      title: 'Delivery Date',
      cellRenderer: ({ cellData }: { cellData: string }) => (
        <Text fontSize={'xs'}>
          {!_.isEmpty(cellData) ? moment(cellData).format('ll') : ''}
        </Text>
      )
    },
    {
      flexGrow: 1,
      key: 'status',
      dataKey: 'status',
      width: 0,
      title: 'Status',
      cellRenderer: ({ cellData }: { cellData: string }) => (
        <OrderStatusTag status={cellData} size='sm' />
      )
    }
  ]

  return (
    <Flex flex={1} direction={'row'} grow={1} p={4} pt={2} ref={containerRef}>
      <BaseTable
        data={data}
        width={width}
        height={height}
        columns={columns}
        rowHeight={50}
        ignoreFunctionInColumnCompare={false}
      />
    </Flex>
  )
}

export default OrdersContent
