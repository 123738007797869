import * as types from 'model/actionTypes'
import { DictT } from 'shared/types/model'

const initialState: DictT<string> = {}

export default function (
  state = initialState,
  action: { type: string; tourSlotId: string; mattertagId: string }
) {
  switch (action.type) {
    case types.RECEIVE_MATTERTAG:
      return {
        ...state,
        [action.tourSlotId]: action.mattertagId
      }
    case types.CLEAR:
    case types.LOGOUT:
      return initialState
    default:
      return state
  }
}
