import * as types from 'model/actionTypes'
import { User } from 'firebase/auth'

export function receiveAuthData (authData: User | null) {
  return {
    type: types.RECEIVE_AUTH_DATA,
    authData
  }
}

export function logoutUser () {
  return {
    type: types.LOGOUT
  }
}
