import {
  Text,
  Flex,
  Box,
  VStack,
  FormControl,
  FormLabel,
  Input,
  HStack,
  Button,
  Heading,
  Divider,
  Tag
} from '@chakra-ui/react'
import { FC, useMemo, useRef, useState, useEffect } from 'react'
import _ from 'lodash'
import { OrderT, DeliveryNotificationT } from 'shared/types/model'
import { Select, OptionBase, GroupBase, MultiValue } from 'chakra-react-select'
import { useSelector } from 'model/hooks'
import { generateId } from 'controllers/db'
import {
  fetchDeliveryNotifications,
  registerNotification
} from 'controllers/orders'
import { Unsubscribe } from 'firebase/firestore'
import moment from 'moment'

interface OptionT extends OptionBase {
  label: string
  value: string
}

type Props = {
  order: OrderT
}

const OrderDelivery: FC<Props> = ({ order }) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([])
  const items = useSelector(state => state.items)
  const [deliveryDate, setDeliveryDate] = useState('')
  const [trackingUrl, setTrackingUrl] = useState('')
  const unsubscribeRef = useRef<Unsubscribe>()
  const [notifications, setNotifications] = useState<DeliveryNotificationT[]>(
    []
  )

  useEffect(() => {
    if (unsubscribeRef.current) unsubscribeRef.current()
    const unsubscribe = fetchDeliveryNotifications(order.id, lst =>
      setNotifications(_.sortBy(lst, 'timestamp'))
    )
    unsubscribeRef.current = unsubscribe
    return () => {
      if (unsubscribeRef.current) unsubscribeRef.current()
    }
  }, [order.id])

  const optionsValue = useMemo(
    () =>
      _.map(selectedItems, itemId => ({
        value: itemId,
        label: _.get(items, [itemId, 'title'], '')
      })),
    [items, selectedItems]
  )

  const options = useMemo(
    () =>
      _.map(order.partnerHandleItems, itemId => ({
        value: itemId,
        label: _.get(items, [itemId, 'title'], '')
      })),
    [order, items]
  )

  const onChange = (options: MultiValue<OptionT>) => {
    const newItemsIds = _.map(options, o => o.value)
    setSelectedItems(newItemsIds)
  }

  const send = () => {
    const notificaion: DeliveryNotificationT = {
      id: generateId(),
      orderId: order.id,
      timestamp: _.now(),
      items: selectedItems,
      deliveryDate
    }
    if (!_.isEmpty(trackingUrl)) notificaion.trackingUrl = trackingUrl
    registerNotification(notificaion)
    setSelectedItems([])
    setDeliveryDate('')
    setTrackingUrl('')
  }

  const sendNotificationForm = () => {
    return (
      <VStack p={4}>
        <FormControl isRequired>
          <FormLabel>Select items we will notify about</FormLabel>
          <Select<OptionT, true, GroupBase<OptionT>>
            isMulti
            closeMenuOnSelect={false}
            selectedOptionStyle='check'
            hideSelectedOptions={false}
            value={optionsValue}
            options={options}
            placeholder='Select items'
            onChange={v => onChange(v)}
            chakraStyles={{
              menu: provided => ({ ...provided, zIndex: 1000 })
            }}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Tracking url (optional)</FormLabel>
          <Input
            type='url'
            value={trackingUrl}
            onChange={e => setTrackingUrl(e.target.value)}
            bg='white'
            onAbort={() => setTrackingUrl('')}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Expected delivery date</FormLabel>
          <Input
            value={deliveryDate}
            type='date'
            onChange={e => setDeliveryDate(e.target.value)}
            bg='white'
            onAbort={() => setDeliveryDate('')}
          />
        </FormControl>
        <HStack w='full' justify={'flex-end'} pt={4}>
          <Button
            colorScheme={'blue'}
            onClick={send}
            isDisabled={_.isEmpty(deliveryDate) || _.isEmpty(selectedItems)}
          >
            Send notification
          </Button>
        </HStack>
      </VStack>
    )
  }

  const renderNotificationInfo = (n: DeliveryNotificationT) => {
    return (
      <HStack key={n.id} w='full' justify={'space-between'}>
        <VStack>
          {_.map(n.items, itemId => (
            <Text fontSize={'xs'} key={itemId}>
              {items && items[itemId].title}
            </Text>
          ))}
        </VStack>
        <VStack spacing={0}>
          <Text fontSize={'xs'} color='gray.600'>
            <b>Sent at:</b> {moment(n.timestamp).format('lll')}
          </Text>
          <Text fontSize={'xs'} color='gray.600'>
            <b>Delivery date:</b> {moment(n.deliveryDate).format('ll')}
          </Text>
        </VStack>
        <Tag colorScheme={n.requestId ? 'teal' : undefined}>
          {n.requestId ? 'Sent' : 'Sending'}
        </Tag>
      </HStack>
    )
  }

  const renderHistory = () => {
    if (!_.isEmpty(notifications)) {
      return (
        <VStack align='flex-start' p={4}>
          <Heading size='sm'>Notifications log:</Heading>
          <VStack divider={<Divider />} w='full'>
            {_.map(notifications, renderNotificationInfo)}
          </VStack>
        </VStack>
      )
    }
  }

  return (
    <Flex
      bg='white'
      borderWidth={1}
      rounded='xl'
      flex={1}
      w='full'
      boxShadow={'base'}
      direction='column'
    >
      <Box px={4} py={4} borderBottomWidth={1} w='full'>
        <Text fontSize={'lg'} color='gray.900' fontWeight={'bold'}>
          Notify customer about delivery
        </Text>
        <Text fontSize={'sm'} color='gray.600' fontWeight={'normal'}>
          The customer will be notified over email
        </Text>
      </Box>
      {sendNotificationForm()}
      {renderHistory()}
    </Flex>
  )
}

export default OrderDelivery
