import _ from 'lodash'
import { setDoc, doc } from 'firebase/firestore'
import {
  AuthError,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut
} from 'firebase/auth'
import { NavigateFunction } from 'react-router-dom'
import { auth, db } from 'controllers/db'
import { authErrorToString } from 'shared/utils/stringFirebaseError'
import { PartnerT } from 'types/model'

export const dbSignIn = async (email: string, password: string) => {
  try {
    await signInWithEmailAndPassword(auth, email, password)
    return null
  } catch (e) {
    const er = e as AuthError
    return authErrorToString(er.code)
  }
}

export const dbSignUp = async (
  name: string,
  email: string,
  password: string
) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    )
    const authUser = userCredential.user
    const userId = authUser.uid
    const dbUser: PartnerT = {
      id: userId,
      name,
      email,
      createdAt: _.now()
    }
    const ref = doc(db, `partners/${userId}`)
    await setDoc(ref, dbUser)
    console.log('user saved', dbUser)
    return null
  } catch (e) {
    console.log('dbSignUp error', e)
    const er = e as AuthError
    return authErrorToString(er.code)
  }
}

export const dbSignOut = async (navigate: NavigateFunction) => {
  try {
    if (auth && auth.currentUser) {
      await signOut(auth)
    } else {
      navigate('/signin')
    }
  } catch (e) {
    if (typeof e === 'string') {
      console.log('signOut error:', e.toUpperCase())
    } else if (e instanceof Error) {
      console.log('signOut error:', e.message)
    }
  }
}
