import { PartnerT } from 'shared/types/model'
import * as types from 'model/actionTypes'

const initialState: PartnerT | null = null

export default function (
  state = initialState,
  action: { type: string; user: PartnerT | null }
) {
  switch (action.type) {
    case types.RECEIVE_USER:
      return action.user
    case types.CLEAR:
    case types.LOGOUT:
      return initialState
    default:
      return state
  }
}
