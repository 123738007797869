import { onSnapshot, query, collection } from 'firebase/firestore'
import _ from 'lodash'

import { db } from 'controllers/db'
import store from 'model/store'
import { addListener } from 'controllers/listeners'
import { receiveSlots } from 'model/actions/slotsAC'
import { DictT, SlotT } from 'shared/types/model'

export const fetchSlots = () => {
  try {
    const q = query(collection(db, 'slots'))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res: DictT<SlotT> = {}
        sn.forEach(doc => {
          const p = doc.data()
          _.set(res, doc.id, p)
        })
        store.dispatch(receiveSlots(res))
      },
      err => {
        console.log(`fetchSlots error: ${err}`)
      }
    )
    addListener('slots', unsubscribe)
  } catch (e) {
    console.log('fetchSlots error', e)
  }
}
