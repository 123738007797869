import { onSnapshot, doc, updateDoc } from 'firebase/firestore'
import { signOut } from 'firebase/auth'

import { db, auth } from 'controllers/db'
import store from 'model/store'
import { addListener } from 'controllers/listeners'
import { receiveUser } from 'model/actions/userAC'
import { PartnerT } from 'shared/types/model'

export const fetchUser = (userId: string) => {
  try {
    const unsubscribe = onSnapshot(
      doc(db, 'partners', userId),
      { includeMetadataChanges: true },
      userSN => {
        const user = userSN.data() as PartnerT | null
        if (user) {
          store.dispatch(receiveUser(user))
        } else {
          signOut(auth)
        }
      },
      err => {
        console.log('fetch user error', err)
      }
    )
    addListener('user', unsubscribe)
  } catch (e) {
    console.log('fetchSlots error', e)
  }
}

export const updateSettings = async (
  userId: string,
  update: Partial<PartnerT>
) => {
  try {
    const ref = doc(db, 'partners', userId)
    await updateDoc(ref, update)
    return true
  } catch (e) {
    console.log('updateSettings error', e)
    return false
  }
}
