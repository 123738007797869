import { combineReducers } from 'redux'
import authData from 'model/reducers/authData'
import tours from 'model/reducers/tours'
import slots from 'model/reducers/slots'
import items from 'model/reducers/items'
import user from 'model/reducers/user'
import tourModels from 'model/reducers/tourModels'
import mattertags from 'model/reducers/mattertags'
import orders from 'model/reducers/orders'
import suppliers from 'model/reducers/suppliers'
import settings from 'model/reducers/settings'
import partners from 'model/reducers/partners'

export const rootReducer = combineReducers({
  authData,
  tours,
  slots,
  items,
  user,
  tourModels,
  mattertags,
  orders,
  suppliers,
  settings,
  partners
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
