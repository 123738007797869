import { Flex, HStack, Text, VStack, Divider, Heading } from '@chakra-ui/react'
import { FC } from 'react'
import { TourModelT, OrderT, TourT, PartnerT } from 'shared/types/model'
import { getAddressLine2 } from 'shared/utils/addressUtils'
import moment from 'moment'
import { formatNational } from 'shared/utils/phone'

type Props = {
  order: OrderT
  tourModel: TourModelT | null
  tour: TourT | null
  partner?: PartnerT
}

const OrderInfoSection: FC<Props> = ({ order, tourModel, tour, partner }) => {
  const renderInfoRow = (title: string, value: string) => {
    return (
      <Flex w='full'>
        <Flex flex={1}>
          <Text fontSize={'sm'} color='gray.800'>
            {title}
          </Text>
        </Flex>
        <Flex flex={2}>
          <Text fontSize={'sm'} color='gray.900' fontWeight={'semibold'}>
            {value}
          </Text>
        </Flex>
      </Flex>
    )
  }

  return (
    <Flex
      bg='white'
      borderWidth={1}
      rounded='xl'
      flex={1}
      w='full'
      // maxWidth={'3xl'}
      boxShadow={'base'}
      direction='column'
    >
      <HStack px={4} py={4} borderBottomWidth={1} justify='space-between'>
        <Text fontSize={'lg'} color='gray.900' fontWeight={'bold'}>
          Order info
        </Text>
      </HStack>
      <VStack align={'flex-start'} w='full' py={4} px={4} spacing={1.5}>
        <Heading size='sm'>Customer</Heading>
        {renderInfoRow('Name', order.name)}
        {renderInfoRow('Email', order.email)}
        {order.phone && renderInfoRow('Phone', formatNational(order.phone))}
        <Divider />
        <Heading size='sm'>Partner</Heading>
        {partner && renderInfoRow('Name', partner.name)}
        {partner && renderInfoRow('Email', partner.email)}
        {partner && <Divider />}
        <Heading size='sm'>Order details</Heading>
        {renderInfoRow('Ordered At', moment(order.timestamp).format('lll'))}
        {tour && renderInfoRow('Design', tour.name)}
        {tourModel && (
          <>
            {renderInfoRow(
              'Property address',
              tourModel.address.structured.main
            )}
            {renderInfoRow('', getAddressLine2(tourModel.address))}
          </>
        )}
      </VStack>
    </Flex>
  )
}

export default OrderInfoSection
