import _ from 'lodash'
import {
  useColorModeValue,
  VStack,
  Checkbox,
  Text,
  Box
} from '@chakra-ui/react'
import { useSelector } from 'model/hooks'
import { getUser } from 'model/selectors/base'
import { SupplierT } from 'types/model'
import { updateSettings } from 'controllers/user'
import NavBar from 'components/NavBar'

const Suppliers = () => {
  const user = useSelector(getUser)
  const suppliers = useSelector(state => state.suppliers)

  const onChange = (supplierId: string, checked: boolean) => {
    if (user) {
      if (checked) {
        const newList = _.uniq([...(user?.suppliers || []), supplierId])
        updateSettings(user?.id, { suppliers: newList })
      } else {
        const newList = _.filter(
          user?.suppliers,
          (id: string) => id !== supplierId
        )
        updateSettings(user?.id, { suppliers: newList })
      }
    } else {
      console.warn('user is undefined', onChange)
    }
  }

  if (!user) {
    return null
  }
  return (
    <VStack h='full' w='full'>
      <NavBar title='Suppliers' />
      <VStack
        spacing={2}
        w='full'
        maxW='2xl'
        bg={useColorModeValue('white', 'gray.700')}
        rounded='xl'
        boxShadow='lg'
        p={6}
        align='flex-start'
      >
        <Box pb={4}>
          <Text color='gray.900' fontWeight={'bold'} fontSize={'lg'}>
            Select suppliers you work with
          </Text>
          <Text fontSize={'sm'} color='gray.600'>
            Items provided by selected suppliers will be available in the
            furniture catalog
          </Text>
        </Box>
        {_.map(suppliers, (s: SupplierT) => {
          return (
            <Checkbox
              key={s.id}
              colorScheme='green'
              isChecked={_.includes(user.suppliers, s.id)}
              onChange={e => onChange(s.id, e.target.checked)}
            >
              {s.name}
            </Checkbox>
          )
        })}
      </VStack>
    </VStack>
  )
}

export default Suppliers
