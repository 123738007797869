const conf = {
  apiKey: 'AIzaSyD3QhSIc-atMEZFMCDJoVdkrqslCBXrK-0',
  authDomain: 'upstager-dev.firebaseapp.com',
  databaseURL: 'https://upstager-dev.firebaseio.com',
  projectId: 'upstager-dev',
  storageBucket: 'upstager-dev.appspot.com',
  messagingSenderId: '817534177303',
  appId: '1:817534177303:web:e6e4ed1c028aedefec8c52',
  measurementId: 'G-MCE1BQ0F2K',
  applicationKey: '9wbhwezsa3eh1yg6a7akc8ssd',
  customerAppUrl: 'https://app.dev.upstager.co',
  partnerAppUrl: 'https://editor.dev.upstager.co',
  internalVersion: '5',
  isDev: true,
  backendUrl: 'https://us-central1-upstager-dev.cloudfunctions.net',
  userAppUrl: 'https://app.dev.upstager.co'
}

export default conf
