import { useEffect, useState, useMemo } from 'react'
import _ from 'lodash'
import {
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  useColorModeValue,
  useToast,
  VStack,
  Flex,
  Image
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import FilesPickerButton from 'shared/components/FilesPickerButton'
import { useSelector } from 'model/hooks'
import { getUser } from 'model/selectors/base'
import { DictT, FileT, PartnerT } from 'types/model'
import { updateSettings } from 'controllers/user'
import NavBar from 'components/NavBar'
import storage from 'controllers/storage'
import { generateId } from 'controllers/db'

const Settings = () => {
  const user = useSelector(getUser)
  const [errors, setErrors] = useState<DictT<string | null>>({})
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState<string>('')
  const [logoUrl, setLogoUrl] = useState<string | undefined>()
  const navigate = useNavigate()
  const toast = useToast()

  useEffect(() => {
    if (user) {
      setName(user.name)
      setLogoUrl(user.logoUrl)
    }
  }, [user])

  const onButtonPress = async () => {
    if (user?.id) {
      setLoading(true)
      const upd: Partial<PartnerT> = { name }
      if (logoUrl) {
        upd.logoUrl = logoUrl
      }
      const res = await updateSettings(user.id, upd)
      if (res) {
        toast({
          title: 'Profile updated',
          status: 'success',
          duration: 5000,
          isClosable: true
        })
        goBack()
      } else {
        toast({
          title: 'Error while updating user profile',
          status: 'error',
          duration: 3000,
          isClosable: true
        })
      }
      setLoading(false)
    }
  }

  const onNameBlur = () => {
    if (_.isEmpty(name)) {
      setErrors({ ...errors, name: 'Please enter your name' })
    } else {
      setErrors({ ...errors, name: null })
    }
  }

  const buttonDisabled = useMemo(() => {
    return _.isEmpty(name)
  }, [name])

  const goBack = () => {
    navigate(-1)
  }

  const onLogoUploaded = (files: FileT[]) => {
    if (_.size(files) > 0) {
      const f = files[0]
      setLogoUrl(f.url)
    }
  }

  return (
    <VStack h='full' w='full'>
      <NavBar title='Settings' />
      <VStack
        spacing={4}
        w='full'
        maxW='2xl'
        bg={useColorModeValue('white', 'gray.700')}
        rounded='xl'
        boxShadow='lg'
        p={6}
      >
        <FormControl id='userName' isInvalid={!_.isEmpty(errors.name)}>
          <FormLabel>Name</FormLabel>
          <Input
            placeholder='UserName'
            _placeholder={{ color: 'gray.500' }}
            type='text'
            value={name}
            onChange={e => setName(e.target.value)}
            onBlur={onNameBlur}
            onFocus={() => setErrors({ ...errors, name: null })}
            isDisabled={loading}
          />
          <FormErrorMessage>{errors.name}</FormErrorMessage>
        </FormControl>
        <VStack align='flex-start' w='full'>
          <FormLabel>Your Logo</FormLabel>
          {logoUrl && <Image boxSize={200} src={logoUrl} alt={user?.id} />}

          {user && (
            <FilesPickerButton
              userId='admin'
              storagePath={`/partners/${user.id}`}
              onComplete={onLogoUploaded}
              buttonTitle={'Upload logo'}
              inputProps={{
                multiple: false,
                accept: 'image/*'
              }}
              storage={storage}
              generateId={generateId}
            />
          )}
        </VStack>
        <Flex justify='flex-end' w='full'>
          <Button
            colorScheme={'teal'}
            onClick={onButtonPress}
            disabled={buttonDisabled}
            isLoading={loading}
          >
            Save
          </Button>
        </Flex>
      </VStack>
    </VStack>
  )
}

export default Settings
