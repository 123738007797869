import { useMemo, useRef } from 'react'
import {
  Wrap,
  WrapItem,
  Image,
  Flex,
  Text,
  Button,
  IconButton
} from '@chakra-ui/react'
import { faChevronRight, faEye } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import CreateTourModal, { ICreateTourModal } from 'modals/CreateTourModal'
import { useSelector } from 'model/hooks'
import { noImageUrl } from 'shared/constants/helpers'
import { TourModelT } from 'shared/types/model'

const ToursContent = () => {
  const tourModelsRow = useSelector(state => state.tourModels)
  const createTourModalRef = useRef<ICreateTourModal>(null)

  const tourModels = useMemo(() => {
    const res: TourModelT[] = []
    _.forEach(tourModelsRow, (tm: TourModelT) => {
      if (tm.enabled) {
        res.push(tm)
      }
    })
    return _.sortBy(res, t => t.createdAt)
  }, [tourModelsRow])

  const renderTMCard = (tm: TourModelT) => {
    return (
      <WrapItem key={tm.id} w='sm' h='xs' position='relative' rounded={'xl'}>
        <Image
          src={tm.photoUrl || noImageUrl}
          rounded={'xl'}
          w='sm'
          h='xs'
          objectFit={'cover'}
        />
        <Flex
          w='full'
          h='50%'
          position='absolute'
          left={0}
          bottom={0}
          bgGradient={'linear(to-b, #00000000, #000000FF)'}
          rounded={'xl'}
          p={6}
          pt={12}
          direction='column'
        >
          <Text color='white' fontWeight={'extrabold'}>
            {tm.address.structured.main}
          </Text>
          <Text color='white' fontWeight={'semibold'} fontSize='small'>
            {tm.address.city}, {tm.address.stateAbbr}
          </Text>

          <Flex pt={6} justify='space-between'>
            <Button
              variant={'link'}
              color='white'
              size='sm'
              rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
              onClick={() => createTourModalRef.current?.open(tm.id)}
            >
              <u>Create Design</u>
            </Button>
            <IconButton
              color='white'
              aria-label='view tour'
              icon={<FontAwesomeIcon icon={faEye} />}
              variant='link'
              onClick={() =>
                window.open(
                  `https://my.matterport.com/show/?m=${tm.modelId}&play=1&ts=5&qs=1&brand=0&dh=1&gt=1&hr=1&mls=2&mt=0&pin=0&portal=0&f=1&lang=en&nozoom=0&wh=1&kb=1&lp=0&title=0&tourcta=0&vr=1`,
                  '_blank'
                )
              }
            />
          </Flex>
        </Flex>
      </WrapItem>
    )
  }

  return (
    <Flex p={4} overflowY='auto'>
      <Wrap spacing='8'>{_.map(tourModels, renderTMCard)}</Wrap>
      <CreateTourModal ref={createTourModalRef} />
    </Flex>
  )
}

export default ToursContent
