import { FC, useMemo, useState } from 'react'
import _ from 'lodash'
import {
  Box,
  Flex,
  Text,
  VStack,
  HStack,
  Button,
  Input,
  FormControl,
  Tooltip
} from '@chakra-ui/react'
import { useSelector } from 'model/hooks'
import { OrderT, TourModelT, TourT, TourSlotT } from 'shared/types/model'
import OrderItem from 'pages/order/OrderItem'
import OrderSummary from 'pages/order/OrderSummary'
import { getItemPhotoUrl, itemsIdsBySlots } from 'shared/utils/itemsUtils'
import { getOrderStatus } from 'utils/orders'
import { updateOrder } from 'controllers/orders'
import orderStatuses from 'shared/constants/orderStatuses'
import { getIsUpstagerTeam } from 'model/selectors/base'
import OrderDelivery from 'pages/order/OrderDelivery'
import NavBar from 'components/NavBar'
import OrderNavBarTitle from 'pages/order/OrderNavBarTitle'
import OrderInfoSection from 'pages/order/OrderInfoSection'

type Props = {
  order: OrderT
}

const OrderContent: FC<Props> = ({ order }) => {
  const suppliers = useSelector(state => state.suppliers)
  const isUpstagerTeam = useSelector(getIsUpstagerTeam)
  const items = useSelector(state => state.items)
  const [deliveryDate, setDeliveryDate] = useState(
    order.partnerDeliveryDate || ''
  )

  // console.log('deliveryDate', deliveryDate)
  const orderStatus = getOrderStatus(order, isUpstagerTeam)

  const itemsIds = _.uniq(_.map(order.items, (ts: TourSlotT) => ts.itemId))

  const [selectedItems, setSelectedItems] = useState(itemsIds)

  const tour: TourT | null = useSelector(state =>
    order.tourId ? state.tours[order.tourId] : null
  )

  const tourModel: TourModelT | null = useSelector(state =>
    state.tourModels ? state.tourModels[order.tourModelId] : null
  )

  const allItemsIds = useMemo(() => {
    return _.filter(
      itemsIdsBySlots(order.items),
      id => order.quantities[id] > 0
    )
  }, [order])

  const sortedItemsIds = useMemo(() => {
    switch (order.status) {
      case orderStatuses.NEW:
        return allItemsIds
      default:
        return order.partnerHandleItems || []
    }
  }, [order])

  const upstagerItems = _.difference(allItemsIds, sortedItemsIds)

  const partnerTotal = useMemo(() => {
    const ids =
      orderStatus === orderStatuses.NEW
        ? selectedItems
        : order.partnerHandleItems
    const prices = _.map(ids, itemId => {
      return (
        _.get(items, [itemId, 'price'], 0) * _.get(order.quantities, itemId, 0)
      )
    })
    return _.sum(prices)
  }, [order, selectedItems])

  const upstagerTotal = useMemo(() => {
    const prices = _.map(upstagerItems, itemId => {
      return (
        _.get(items, [itemId, 'price'], 0) * _.get(order.quantities, itemId, 0)
      )
    })
    return _.sum(prices)
  }, [order])

  const toggleItem = (itemId: string, v: boolean) => {
    if (v) {
      setSelectedItems(selectedItems => _.uniq([...selectedItems, itemId]))
    } else {
      setSelectedItems(selectedItems =>
        _.filter(selectedItems, id => id !== itemId)
      )
    }
  }

  const renderOrderItems = () => {
    const canSelect = order.status === orderStatuses.NEW
    return (
      <Flex
        bg='white'
        borderWidth={1}
        rounded='xl'
        flex={1}
        // minWidth='xl'
        // maxWidth={'3xl'}
        boxShadow={'base'}
        direction='column'
      >
        <Box px={4} py={4} borderBottomWidth={1}>
          <Text fontSize={'lg'} color='gray.900' fontWeight={'bold'}>
            Items
          </Text>
          <Text fontSize={'sm'} color='gray.600' fontWeight={'normal'}>
            {orderStatus === orderStatuses.REVIEW
              ? 'You are responsible for the items'
              : 'Select items that you are able to deliver'}
          </Text>
        </Box>
        <VStack align={'flex-start'} w='full' py={4}>
          {_.map(sortedItemsIds, (itemId: string) => {
            const item = items && items[itemId]
            if (!item) return null
            const name = _.get(item, 'title', '')
            const description = _.get(item, 'desc', '')
            const price = _.get(item, 'price', 0)
            const quantity = _.get(order.quantities, itemId, 0)

            return (
              <OrderItem
                key={item.id}
                name={name}
                description={description}
                price={price}
                quantity={quantity}
                imageUrl={getItemPhotoUrl(item)}
                checked={_.includes(selectedItems, itemId)}
                toggleItem={(v: boolean) => toggleItem(itemId, v)}
                canSelect={canSelect}
                supplier={
                  item.supplierId
                    ? _.get(suppliers, [item.supplierId, 'name'])
                    : '-'
                }
              />
            )
          })}
          <OrderSummary title={'Subtotal'} size='md' total={partnerTotal} />
        </VStack>
      </Flex>
    )
  }

  const apply = () => {
    const upd: Partial<OrderT> = {
      partnerHandleItems: selectedItems,
      partnerDeliveryDate: deliveryDate
    }
    if (_.size(selectedItems) === _.size(itemsIds) || isUpstagerTeam) {
      upd.status = orderStatuses.WAITING_FOR_PAYMENT
    } else {
      upd.status = orderStatuses.REVIEW
      upd.handledByUpstager = true
    }
    updateOrder(order.id, upd)
  }

  const actionsNew = (
    <Flex w='full' justify={'space-between'}>
      <Box>
        <FormControl isRequired>
          <HStack align={'center'}>
            <Box minW='24' lineHeight={1.2}>
              <Text fontSize={'sm'} color='gray.600'>
                Expected delivery date
              </Text>
            </Box>
            <Input
              value={deliveryDate}
              type='date'
              onChange={e => setDeliveryDate(e.target.value)}
              bg='white'
              onAbort={() => setDeliveryDate('')}
            />
            <Tooltip
              label={
                _.isEmpty(selectedItems)
                  ? 'Select at least one item'
                  : 'Set the expected delivery date'
              }
              isDisabled={!_.isEmpty(deliveryDate) && !_.isEmpty(selectedItems)}
              shouldWrapChildren
            >
              <Button
                colorScheme='blue'
                isDisabled={_.isEmpty(deliveryDate) || _.isEmpty(selectedItems)}
                onClick={apply}
              >
                Confirm
              </Button>
            </Tooltip>
          </HStack>
        </FormControl>
      </Box>
    </Flex>
  )

  const onComplete = () => {
    const upd: Partial<OrderT> = {}
    if (isUpstagerTeam || !order.handledByUpstager) {
      upd.status = orderStatuses.DONE
    } else {
      upd.partnerCompleted = _.now()
    }
    updateOrder(order.id, upd)
  }

  const renderActions = () => {
    switch (orderStatus) {
      case orderStatuses.NEW:
        return actionsNew
      case orderStatuses.PAID:
        return (
          <Button colorScheme={'teal'} onClick={onComplete}>
            Complete
          </Button>
        )
      default:
        return null
    }
  }

  const renderOrderDelivery = () => {
    if (orderStatus === orderStatuses.PAID) {
      return <OrderDelivery order={order} />
    }
  }

  const renderUpstagerItems = () => {
    if (orderStatus === orderStatuses.REVIEW) {
      return (
        <Flex
          bg='white'
          borderWidth={1}
          rounded='xl'
          flex={1}
          // minWidth='xl'
          // maxWidth={'3xl'}
          boxShadow={'base'}
          direction='column'
        >
          <Box px={4} py={4} borderBottomWidth={1}>
            <Text fontSize={'lg'} color='gray.900' fontWeight={'bold'}>
              UpStager Items
            </Text>
            <Text fontSize={'sm'} color='gray.600' fontWeight={'normal'}>
              The items will be handled by UpStager
            </Text>
          </Box>
          <VStack align={'flex-start'} w='full' py={4}>
            {_.map(upstagerItems, (itemId: string) => {
              const item = items && items[itemId]
              if (!item) return null
              const name = _.get(item, 'title', '')
              const description = _.get(item, 'desc', '')
              const price = _.get(item, 'price', 0)
              const quantity = _.get(order.quantities, itemId, 0)

              return (
                <OrderItem
                  key={item.id}
                  name={name}
                  description={description}
                  price={price}
                  quantity={quantity}
                  imageUrl={getItemPhotoUrl(item)}
                  canSelect={false}
                  supplier={
                    item.supplierId
                      ? _.get(suppliers, [item.supplierId, 'name'])
                      : '-'
                  }
                />
              )
            })}
            <OrderSummary title={'Subtotal'} size='md' total={upstagerTotal} />
          </VStack>
        </Flex>
      )
    }
  }

  const renderOrderSummary = () => {
    return <OrderSummary total={upstagerTotal + partnerTotal} />
  }

  return (
    <VStack h='full' w='full'>
      <NavBar
        title={
          <OrderNavBarTitle
            order={order}
            orderStatus={orderStatus}
            tourModel={tourModel}
          />
        }
      >
        {renderActions()}
      </NavBar>
      <Flex
        direction={'column'}
        w='full'
        overflow={'hidden'}
        flex={1}
        align='center'
      >
        <Flex
          pt={2}
          pb={12}
          overflowY={'auto'}
          direction='column'
          w='full'
          align={'center'}
        >
          <VStack spacing={4} maxWidth={'3xl'}>
            <OrderInfoSection order={order} tourModel={tourModel} tour={tour} />
            {renderOrderItems()}
            {renderUpstagerItems()}
            {renderOrderDelivery()}
            {renderOrderSummary()}
          </VStack>
        </Flex>
      </Flex>
    </VStack>
  )
}

export default OrderContent
