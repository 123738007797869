import { FC } from 'react'
import _ from 'lodash'
import {
  Flex,
  Heading,
  Box,
  Button,
  VStack,
  HStack,
  WrapItem,
  Image,
  Wrap,
  Text,
  Badge
} from '@chakra-ui/react'

import RightPanelWrapper from 'shared/components/RightPanelWrapper'
import { ItemT, SlotT, DictT, SupplierT } from 'shared/types/model'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons'
import { getItemPhotoUrl } from 'shared/utils/itemsUtils'
import numeral from 'numeral'

type Props = {
  toCatalog: () => void
  closeCatalog: () => void
  addToRoom: (itemId: string) => void
  selectedItemId: string | null
  openProductCard: (itemId: string) => void
  slot: SlotT
  items: DictT<ItemT>
  suppliers?: DictT<SupplierT>
}

const CategoryItems: FC<Props> = ({
  toCatalog,
  closeCatalog,
  addToRoom,
  selectedItemId,
  openProductCard,
  slot,
  items,
  suppliers
}) => {
  const renderItems = () => {
    return (
      <Wrap spacing={4}>
        {_.map(items, (item: ItemT) => {
          return (
            <WrapItem key={item.id} scrollSnapAlign='start'>
              <VStack align={'flex-start'} pb={4} w={'164px'} spacing={1}>
                <Box
                  as={item.id !== selectedItemId ? 'button' : undefined}
                  // _hover={{ boxShadow: 'md' }}
                  onClick={() => addToRoom(item.id)}
                >
                  <Image
                    boxSize={164}
                    alt={item.title}
                    src={getItemPhotoUrl(item)}
                  />
                </Box>
                <Text
                  fontSize={'xs'}
                  color='gray.600'
                  noOfLines={2}
                  h='8'
                  lineHeight={1.2}
                >
                  {item.title}
                </Text>
                {suppliers && item.supplierId && (
                  <Badge colorScheme='green'>
                    {_.truncate(_.get(suppliers, [item.supplierId, 'name']), {
                      length: 20
                    })}
                  </Badge>
                )}
                <Flex align='center' justify={'space-between'} w='full' pb={2}>
                  <Text
                    fontSize={'xs'}
                    color='gray.900'
                    fontWeight={'semibold'}
                    lineHeight={1}
                  >
                    {numeral(item.price).format('$0,0.00')}
                  </Text>

                  <Button
                    size='xs'
                    color='blue.500'
                    variant={'unstyled'}
                    onClick={() => openProductCard(item.id)}
                  >
                    more info
                  </Button>
                </Flex>
                <Button
                  size='sm'
                  colorScheme={'teal'}
                  w='full'
                  onClick={() => addToRoom(item.id)}
                  disabled={item.id === selectedItemId}
                >
                  {selectedItemId ? 'Select' : 'Add to room'}
                </Button>
              </VStack>
            </WrapItem>
          )
        })}
      </Wrap>
    )
  }

  return (
    <RightPanelWrapper show width={380}>
      <Flex flex={1} maxH='full' direction='column'>
        <Box pb={8} bg='white' p={4}>
          <Flex align='flex-start' justify={'space-between'}>
            <HStack align='flex-start' spacing={4}>
              <Box as='button' onClick={() => toCatalog()}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </Box>
              <VStack align={'flex-start'}>
                <Heading size='md' color='black'>
                  {slot?.title}
                </Heading>
              </VStack>
            </HStack>
            <Button
              size='xs'
              variant={'ghost'}
              color='blue.500'
              onClick={() => closeCatalog()}
            >
              Done
            </Button>
          </Flex>
        </Box>
        <Flex
          direction='column'
          align={'flex-start'}
          justify='flex-start'
          flex={1}
          h='full'
          overflowX='hidden'
          overflowY={'auto'}
          scrollSnapType={'y proximity'}
          px={4}
        >
          {renderItems()}
        </Flex>
      </Flex>
    </RightPanelWrapper>
  )
}

export default CategoryItems
