import { useState, ReactChild, ReactChildren, FC } from 'react'
// import { useDisclosure } from '@chakra-ui/react'
import { motion } from 'framer-motion'

type Props = {
  children: ReactChild | ReactChildren
  isOpen: boolean
  width: number
}

const HorizontalCollapse: FC<Props> = ({ children, isOpen, width }) => {
  // const { getButtonProps, getDisclosureProps, isOpen } = useDisclosure()
  const [hidden, setHidden] = useState(!isOpen)

  return (
    <motion.div
      // {...getDisclosureProps()}
      hidden={hidden}
      initial={false}
      onAnimationStart={() => setHidden(false)}
      onAnimationComplete={() => setHidden(!isOpen)}
      animate={{ width: isOpen ? width : 0 }}
      style={{
        height: '100vh'
      }}
      // style={{
      //   background: 'red',
      //   overflow: 'hidden',
      //   whiteSpace: 'nowrap',
      //   position: 'absolute',
      //   right: '0',
      //   height: '100vh',
      //   top: '0'
      // }}
    >
      {children}
    </motion.div>
  )
}

export default HorizontalCollapse
