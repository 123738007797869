import { FC } from 'react'
import { VStack, Flex } from '@chakra-ui/react'
import OrdersContent from 'pages/orders/OrdersContent'
import NavBar from 'components/NavBar'

const Orders: FC = () => {
  return (
    <VStack h='full' w='full'>
      <NavBar title='Orders' />
      <Flex
        direction={'column'}
        w='full'
        justify={'flex-start'}
        overflow={'hidden'}
        flex={1}
      >
        <OrdersContent />
      </Flex>
    </VStack>
  )
}

export default Orders
