import { onSnapshot, query, collection } from 'firebase/firestore'
import _ from 'lodash'

import { db } from 'controllers/db'
import store from 'model/store'
import { addListener } from 'controllers/listeners'
import { SupplierT } from 'shared/types/model'
import { receiveSuppliers } from 'model/actions/suppliersAC'

export const fetchSuppliers = () => {
  try {
    const q = query(collection(db, 'suppliers'))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res = {}
        sn.forEach(doc => {
          const p = doc.data() as SupplierT
          _.set(res, doc.id, p)
        })
        store.dispatch(receiveSuppliers(res))
      },
      err => {
        console.log(`createSupplier error: ${err}`)
      }
    )
    addListener('suppliers', unsubscribe)
  } catch (e) {
    console.log('createSupplier error', e)
  }
}
