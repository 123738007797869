import { FC } from 'react'
import _ from 'lodash'
import {
  Flex,
  Heading,
  Box,
  Button,
  VStack,
  HStack,
  Wrap,
  WrapItem,
  Image,
  Text,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription
} from '@chakra-ui/react'

import RightPanelWrapper from 'shared/components/RightPanelWrapper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons'
import { DictT, ItemT, SlotT } from 'shared/types/model'

type Props = {
  goBack: () => void
  selectCategory: (slotId: string) => void
  selectedItemId: string | null
  itemsBySlot: DictT<DictT<ItemT>>
  slots: DictT<SlotT>
  isEditor?: boolean
  toSuppliers?: () => void
}

const CatalogPanel: FC<Props> = ({
  goBack,
  selectCategory,
  selectedItemId,
  itemsBySlot,
  slots,
  isEditor,
  toSuppliers
}) => {
  const renderCategories = () => {
    return (
      <Wrap spacing={4}>
        {_.map(itemsBySlot, (items, slotId) => {
          const s = slots && slots[slotId]
          if (s) {
            return (
              <WrapItem key={slotId}>
                <VStack align={'flex-start'} pb={4}>
                  <Box
                    as='button'
                    _hover={{ boxShadow: 'md' }}
                    onClick={() => selectCategory(slotId)}
                  >
                    <Image
                      boxSize={164}
                      alt={s.title}
                      src={s.photo?.thumbnailUrl || s.photo?.url}
                    />
                  </Box>
                  <Text fontSize={'xs'} color='blackAlpha.900'>
                    {s.title}
                  </Text>
                </VStack>
              </WrapItem>
            )
          }
        })}
      </Wrap>
    )
  }

  const header = (
    <Box pb={8} bg='white' p={4}>
      <Flex align='flex-start' justify={'space-between'}>
        <HStack align='flex-start' spacing={4} w='full'>
          {!selectedItemId && (
            <Box as='button' onClick={() => goBack()}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </Box>
          )}
          <Flex align={'center'} justify='space-between' w={'full'}>
            <VStack align={'flex-start'}>
              <Heading size='md' color='black'>
                Catalog
              </Heading>
            </VStack>
            {selectedItemId && (
              <Button
                size='xs'
                variant={'ghost'}
                color='blue.500'
                onClick={() => goBack()}
              >
                Done
              </Button>
            )}
          </Flex>
        </HStack>
      </Flex>
    </Box>
  )

  if (isEditor && _.isEmpty(itemsBySlot)) {
    return (
      <RightPanelWrapper show width={380}>
        <Flex flex={1} maxH='full' direction='column'>
          {header}
          <Alert
            status='warning'
            variant='subtle'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            textAlign='center'
          >
            <AlertIcon boxSize='40px' mr={0} />
            <AlertTitle mt={4} mb={1} fontSize='lg'>
              No suppliers!
            </AlertTitle>
            <AlertDescription maxWidth='sm'>
              Please, select suppliers you work with. Items provided by the
              supplier will available in the catalog.
            </AlertDescription>
            <Box pt={4}>
              <Button colorScheme={'blue'} onClick={toSuppliers}>
                Setup suppliers
              </Button>
            </Box>
          </Alert>
        </Flex>
      </RightPanelWrapper>
    )
  } else {
    return (
      <RightPanelWrapper show width={380}>
        <Flex flex={1} maxH='full' direction='column'>
          {header}
          <Flex
            direction='column'
            align={'center'}
            justify='flex-start'
            flex={1}
            h='full'
            overflowX='hidden'
            overflowY={'auto'}
            scrollSnapType={'y proximity'}
            p={4}
          >
            {renderCategories()}
          </Flex>
        </Flex>
      </RightPanelWrapper>
    )
  }
}

export default CatalogPanel
