import { createSelector } from '@reduxjs/toolkit'
import _ from 'lodash'
import { getItems } from 'model/selectors/base'
import { DictT, ItemT } from 'shared/types/model'

export const getItemsBySlot = createSelector([getItems], items => {
  const res: DictT<DictT<ItemT>> = {}
  _.forEach(items, item => {
    if (item.enabled) {
      _.set(res, [item.kind, item.id], item)
    }
  })
  return res
})
