import {
  onSnapshot,
  collection,
  query,
  where,
  doc,
  updateDoc,
  setDoc
} from 'firebase/firestore'
import _ from 'lodash'

import { db } from 'controllers/db'
import store from 'model/store'
import { addListener } from 'controllers/listeners'
import { receiveOrders } from 'model/actions/ordersAC'
import { OrderT, DeliveryNotificationT } from 'shared/types/model'

export const fetchOrders = (userId: string, isUpstagerTeam: boolean) => {
  try {
    // console.log('fetch orders', userId)
    const q = query(collection(db, 'orders'), where('partnerId', '==', userId))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res = {}
        sn.forEach(doc => {
          const p = doc.data()
          _.set(res, doc.id, { ...p, id: doc.id })
        })
        // console.log('orders received, amount', _.size(res))
        store.dispatch(receiveOrders(res))
      },
      err => {
        console.log(`fetchOrders error: ${err}`)
      }
    )
    addListener('orders', unsubscribe)

    if (isUpstagerTeam) {
      const q = query(
        collection(db, 'orders'),
        where('handledByUpstager', '==', true)
      )
      const unsubscribe = onSnapshot(
        q,
        sn => {
          const res = {}
          sn.forEach(doc => {
            const p = doc.data()
            _.set(res, doc.id, { ...p, id: doc.id })
          })
          store.dispatch(receiveOrders(res))
        },
        err => {
          console.log(`fetchOrders error: ${err}`)
        }
      )
      addListener('upstager_orders', unsubscribe)
    }
  } catch (e) {
    console.log('fetchOrders error', e)
  }
}

export const updateOrderStatus = (orderId: string, status: string) => {
  try {
    const ref = doc(db, 'orders', orderId)
    updateDoc(ref, { status })
  } catch (e) {
    console.error('cannot update order status', e)
  }
}

export const updateOrder = (orderId: string, upd: Partial<OrderT>) => {
  try {
    console.log('update order', upd)
    const ref = doc(db, 'orders', orderId)
    updateDoc(ref, upd)
  } catch (e) {
    console.error('cannot update order status', e)
  }
}

export const registerNotification = (notification: DeliveryNotificationT) => {
  try {
    console.log('registerNotification', notification)
    const ref = doc(db, 'deliveryNotifications', notification.id)
    setDoc(ref, notification)
  } catch (e) {
    console.error('cannot update order status', e)
  }
}

export const fetchDeliveryNotifications = (
  orderId: string,
  onUpdate: (notifications: DeliveryNotificationT[]) => void
) => {
  const q = query(
    collection(db, 'deliveryNotifications'),
    where('orderId', '==', orderId)
  )
  const unsubscribe = onSnapshot(
    q,
    sn => {
      onUpdate(_.map(sn.docs, doc => doc.data() as DeliveryNotificationT))
    },
    err => {
      console.log(`fetchDeliveryNotifications error: ${err}`)
    }
  )
  return unsubscribe
}
