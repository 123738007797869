import * as libphone from 'libphonenumber-js'
import _ from 'lodash'

// +15005550002 format
export const formatPhone = (strPhone: string) => {
  try {
    const phoneNumber = libphone.parsePhoneNumber(strPhone, 'US')
    if (phoneNumber && phoneNumber.isValid()) {
      return phoneNumber.number
    } else {
      console.warn('formatPhone cannot format, strPhone', strPhone)
      return null
    }
  } catch (e) {
    console.warn('formatPhone, strPhone', strPhone, 'error', e)
    return null
  }
}

// 5005550002 format
export const formatPhone10Digits = (strPhone: string) => {
  try {
    const phoneNumber = libphone.parsePhoneNumber(strPhone, 'US')
    if (phoneNumber && phoneNumber.isValid()) {
      return phoneNumber.nationalNumber
    } else {
      console.warn('formatPhone10Digits cannot format, strPhone', strPhone)
      return null
    }
  } catch (e) {
    console.warn('formatPhone10Digits, strPhone', strPhone, 'error', e)
    return null
  }
}

// (213) 373-4253 format
export const formatNational = (strPhone: string) => {
  try {
    const phoneNumber = libphone.parsePhoneNumber(strPhone, 'US')
    if (phoneNumber && phoneNumber.isValid()) {
      return phoneNumber.formatNational()
    } else {
      console.warn('formatNational cannot format, strPhone', strPhone)
      return strPhone
    }
  } catch (e) {
    console.warn('formatNational, strPhone', strPhone, 'error', e)
    return strPhone
  }
}

export const formatPhoneInput = (v: string) => {
  if (!_.isString(v)) {
    return v
  } else if (v && v.length < 7) {
    return v
  } else {
    const national = formatNational(v)
    if (_.isNil(national)) {
      const aYT = new libphone.AsYouType('US')
      return aYT.input(v)
    } else {
      return national
    }
  }
}
