import { DictT, OrderT } from 'shared/types/model'
import * as types from 'model/actionTypes'

const initialState: DictT<OrderT> = {}

export default function (
  state = initialState,
  action: { type: string; orders: DictT<OrderT> }
) {
  switch (action.type) {
    case types.RECEIVE_ORDERS:
      return {
        ...state,
        ...action.orders
      }
    case types.CLEAR:
    case types.LOGOUT:
      return initialState
    default:
      return state
  }
}
