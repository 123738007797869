import { FC, useRef } from 'react'
import { Button, VStack, Flex } from '@chakra-ui/react'

import CreateTourModal, { ICreateTourModal } from 'modals/CreateTourModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import DesignsContent from 'pages/designs/DesignsContent'
import NavBar from 'components/NavBar'

const Designs: FC = () => {
  const modalRef = useRef<ICreateTourModal>(null)

  const openCreateTourModal = () => {
    modalRef.current?.open()
  }

  return (
    <VStack h='full' w='full'>
      <NavBar title='Designs'>
        <Button
          leftIcon={<FontAwesomeIcon icon={faPlus} />}
          variant={'solid'}
          colorScheme='teal'
          onClick={() => openCreateTourModal()}
          size='sm'
        >
          Create Design
        </Button>
      </NavBar>
      <Flex
        direction={'column'}
        w='full'
        justify={'flex-start'}
        overflow={'hidden'}
        flex={1}
      >
        <DesignsContent />
      </Flex>
      <CreateTourModal ref={modalRef} />
    </VStack>
  )
}

export default Designs
