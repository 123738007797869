// import _ from 'lodash'
import { useEffect, FC, useRef } from 'react'

import { MpSdk, Scene, Mode } from 'shared/bundle/sdk'
import { moveButtonType } from 'shared/components/matterport/MMoveButton'
import { ComponentInteractionType } from 'shared/components/SceneComponent'
import { Vector3 } from 'three'

type Props = {
  sdk: MpSdk
  itemKey: string | null
  position: Vector3
  size: number
  initialPose: MpSdk.Camera.Pose | undefined
  onStartMoving: () => void
  viewMode: Mode.Mode
  visible?: boolean
}

const MoveButton: FC<Props> = ({
  sdk,
  position,
  size,
  initialPose,
  onStartMoving,
  viewMode,
  visible = false
}) => {
  const nodeRef = useRef<MpSdk.Scene.INode | null>(null)
  const compRef = useRef<MpSdk.Scene.IComponent | null>(null)
  const spyRef = useRef<MpSdk.ISubscription | null>(null)
  const poseRef = useRef<MpSdk.Camera.Pose>()
  const positionRef = useRef<Vector3>(position)
  const viewModeRef = useRef<Mode.Mode>(sdk.Mode.Mode.INSIDE)

  useEffect(() => {
    viewModeRef.current = viewMode
  }, [viewMode])

  useEffect(() => {
    const comp = compRef.current
    if (comp && comp.inputs) {
      comp.inputs.visible = visible
    }
  }, [visible])

  const calcAndUpdatePosition = () => {
    const pose = poseRef.current || initialPose
    // console.log('calc and update position, viewMode', viewModeRef.current)
    if (pose) {
      const comp = compRef.current
      switch (viewModeRef.current) {
        case sdk.Mode.Mode.INSIDE: {
          if (comp && comp.inputs) {
            const v = new Vector3(
              pose.position.x,
              positionRef.current.y,
              pose.position.z
            )
            // console.log('cameraFloorPosition orig', v)
            v.sub(positionRef.current)
            // console.log('cameraFloorPosition after sub', v)
            v.normalize()
            // console.log('cameraFloorPosition after normalization', v)
            // console.log('multK')
            const multK = (size / 2) * 0.2
            v.multiplyScalar(multK)
            // v.addScalar()
            const p = positionRef.current.clone().add(v)
            // console.log('set comp position', p)
            comp.inputs.position = p
            comp.inputs.vector = v
          }
          break
        }
        case sdk.Mode.Mode.FLOORPLAN: {
          if (comp && comp.inputs) {
            const v = new Vector3(0, positionRef.current.y, 1)
            const p = positionRef.current
              .clone()
              .add(new Vector3(0, 0, size / 2))
            comp.inputs.position = p
            comp.inputs.vector = v
          }
          break
        }
      }
    }
  }

  useEffect(() => {
    const unsubscibe = sdk.Camera.pose.subscribe(function (pose) {
      poseRef.current = pose
      calcAndUpdatePosition()
    })
    return () => unsubscibe.cancel()
  }, [])

  useEffect(() => {
    positionRef.current = position
    calcAndUpdatePosition()
  }, [position])

  useEffect(() => {
    console.log('new move button')
    const run = async () => {
      const sceneObjs: Array<MpSdk.Scene.IObject> =
        await sdk.Scene.createObjects(1)
      const sceneObj = sceneObjs[0]
      const node = sceneObj.addNode()
      nodeRef.current = node
      const moveComponent = node.addComponent(moveButtonType, {
        position
      })

      compRef.current = moveComponent

      moveComponent.emits = { [ComponentInteractionType.CLICK]: true }

      const spyPath = sceneObj.addPath({
        id: 'move_control_spy',
        type: 'emit' as Scene.PathType.EMIT,
        node: node,
        component: moveComponent,
        property: ComponentInteractionType.CLICK
      })
      const spy = {
        path: spyPath,
        onEvent () {
          console.log('on click')
          onStartMoving()
        }
      }

      spyRef.current = sceneObj.spyOnEvent(spy)

      console.log('compRef.current', compRef.current)
      node.start()
      calcAndUpdatePosition()
    }
    run()
    return () => {
      const subscr = spyRef.current
      if (subscr) {
        subscr.cancel()
      }
      const node = nodeRef.current
      node?.stop()
    }
  }, [])

  return null
}

export default MoveButton
