import _ from 'lodash'
import { NavigateFunction } from 'react-router-dom'
import { auth } from 'controllers/db'
import { onAuthStateChanged, User } from 'firebase/auth'
import { receiveAuthData, logoutUser } from 'model/actions/authDataAC'
import { fetchTours } from 'controllers/tours'
import { fetchSlots } from 'controllers/slots'
import { fetchItems } from 'controllers/items'
import { fetchUser } from 'controllers/user'
import { fetchOrders } from 'controllers/orders'
import store from 'model/store'
import { clearListeners } from 'controllers/listeners'
import { fetchTourModels } from 'controllers/tourModels'
import { fetchSuppliers } from 'controllers/suppliers'
import { fetchSettings } from 'controllers/settings'
import { fetchPartners } from 'controllers/partners'

const init = async (userId: string) => {
  console.log('INIT', userId)
  const settings = await fetchSettings()
  if (settings) {
    const isUpstagerTeam = settings.defaultPartnerId === userId
    console.log('isUpstagerTeam', isUpstagerTeam)
    fetchUser(userId)
    fetchTourModels()
    fetchTours(userId)
    fetchSlots()
    fetchItems()
    fetchOrders(userId, isUpstagerTeam)
    fetchSuppliers()
    if (isUpstagerTeam) {
      fetchPartners()
    }
  }
}

const onAuth = (authData: User | null, navigate: NavigateFunction) => {
  console.log('onAuthStateChanged', authData)
  if (authData) {
    console.log('authData', authData)
    store.dispatch(receiveAuthData(authData))
    const pathname = window.location.pathname
    if (_.startsWith(pathname, '/sign')) {
      navigate('/')
    }
    init(authData.uid)
  } else {
    store.dispatch(receiveAuthData(null))
    clearListeners()
    navigate('/signin')
    store.dispatch(logoutUser())
  }
}

export const appInitialized = (navigate: NavigateFunction): void => {
  try {
    onAuthStateChanged(auth, authData => onAuth(authData, navigate))
  } catch (e) {
    // Sentry.captureException(e)
    console.log('app initialization error', e)
  }
}
