import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Flex,
  SliderMark
} from '@chakra-ui/react'
import { FC } from 'react'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsLeftRight } from '@fortawesome/pro-solid-svg-icons'

type Props = {
  onRotated: (v: number) => void
  modelRotate: number
}

const RotateControlSlider: FC<Props> = ({ modelRotate, onRotated }) => {
  const onChange = (v: number) => {
    console.log('onChange', v)
    onRotated(v + 90)
  }

  const labelStyles = {
    mt: '3',
    ml: '-2.5',
    fontSize: 'sm',
    textShadow:
      '0.5px 0px 1px white, -0.5px 0px 1px white, 0px 0.5px 1px white, 0px -0.5px 1px white',
    fontWeight: 'semibold'
  }

  const sliderValue = _.round(modelRotate - 90)

  return (
    <Flex w='400px' position='absolute' bottom={8} left='calc(50% - 200px)'>
      <Slider
        aria-label='slider-ex-1'
        value={sliderValue}
        onChange={v => onChange(v)}
        min={-180}
        max={180}
        step={5}
      >
        <SliderMark value={-180} {...labelStyles} ml={0}>
          Rotation
        </SliderMark>
        <SliderMark value={-90} {...labelStyles} ml={-4}>
          -90°
        </SliderMark>
        <SliderMark value={0} {...labelStyles} ml={-1}>
          0°
        </SliderMark>
        <SliderMark value={90} {...labelStyles} ml={-2.5}>
          90°
        </SliderMark>
        <SliderMark value={180} {...labelStyles}>
          180°
        </SliderMark>
        <SliderMark
          value={sliderValue}
          textAlign='center'
          bg='blue.500'
          rounded={'base'}
          color='white'
          mt='-10'
          ml='-6'
          w='12'
        >
          {sliderValue}°
        </SliderMark>
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb boxSize={6} color='blue.600'>
          <FontAwesomeIcon icon={faArrowsLeftRight} />
        </SliderThumb>
      </Slider>
    </Flex>
  )
}

export default RotateControlSlider
