import { onSnapshot, query, collection } from 'firebase/firestore'
import _ from 'lodash'

import { db } from 'controllers/db'
import store from 'model/store'
import { addListener } from 'controllers/listeners'
import { DictT, PartnerT } from 'shared/types/model'
import { receivePartners } from 'model/actions/partnersAC'

export const fetchPartners = () => {
  try {
    const q = query(collection(db, 'partners'))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res: DictT<PartnerT> = {}
        sn.forEach(doc => {
          const p = doc.data() as PartnerT
          _.set(res, doc.id, p)
        })
        console.log('receive partners', res)
        store.dispatch(receivePartners(res))
      },
      err => {
        console.log(`fetchPartners error: ${err}`)
      }
    )
    addListener('partners', unsubscribe)
  } catch (e) {
    console.log('fetchPartners error', e)
  }
}
