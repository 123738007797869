import * as types from 'model/actionTypes'
import { DictT, PartnerT } from 'shared/types/model'

const initialState: DictT<PartnerT> | null = null

export default function (
  state = initialState,
  action: { type: string; partners: DictT<PartnerT> }
) {
  switch (action.type) {
    case types.RECEIVE_PARTNERS:
      return {
        ...state,
        ...action.partners
      }
    case types.CLEAR:
    case types.LOGOUT:
      return initialState
    default:
      return state
  }
}
