import { FC } from 'react'
import {
  HStack,
  IconButton,
  VStack,
  Heading,
  Text,
  Tag
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons'
import { useNavigate } from 'react-router-dom'
import { TourModelT, OrderT } from 'shared/types/model'
import { getAddress } from 'shared/utils/addressUtils'
import { statusInfo } from 'utils/orders'

type Props = {
  tourModel: TourModelT | null
  order: OrderT
  orderStatus: string
}

const OrderNavBarTitle: FC<Props> = ({ order, tourModel, orderStatus }) => {
  const navigate = useNavigate()
  return (
    <HStack justify={'space-between'} w='full'>
      <HStack spacing={4}>
        <IconButton
          aria-label='back'
          icon={<FontAwesomeIcon icon={faChevronLeft} />}
          variant='outline'
          size='md'
          onClick={() => navigate('/orders')}
        />
        <VStack spacing={0} align='flex-start'>
          <Heading size={'md'}>{order.name}</Heading>
          <Text fontSize={'sm'}>
            {tourModel && getAddress(tourModel?.address)}
          </Text>
        </VStack>
        <Tag
          size={'sm'}
          variant='solid'
          colorScheme={statusInfo[orderStatus].color}
        >
          {statusInfo[orderStatus].title}
        </Tag>
      </HStack>
    </HStack>
  )
}

export default OrderNavBarTitle
