import { FC, useMemo } from 'react'
import _ from 'lodash'
import {
  Flex,
  Heading,
  Box,
  Button,
  VStack,
  HStack,
  Text
} from '@chakra-ui/react'

import RightPanelWrapper from 'shared/components/RightPanelWrapper'
import { useSelector } from 'model/hooks'
import { TourT, DictT, TourSlotT } from 'shared/types/model'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PanelCartItem from 'shared/components/PanelCartItem'
import numeral from 'numeral'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { dbUpdateTourPath } from 'controllers/tours'
import { MpSdk } from 'shared/bundle/sdk'

type Props = {
  sdk: MpSdk
  tour: TourT
  onAddFurnitureClick: () => void
  openProductCard: (itemId: string) => void
  addToRoom: (itemId: string) => void
  removeItem: (itemId: string) => void
}

const ProDesignPanel: FC<Props> = ({
  sdk,
  tour,
  onAddFurnitureClick,
  openProductCard,
  addToRoom,
  removeItem
}) => {
  const items = useSelector(state => state.items)
  const suppliers = useSelector(state => state.suppliers)
  const mattertags = useSelector(state => state.mattertags)
  const sortedItems = useMemo(() => {
    const byItemId: DictT<TourSlotT[]> = _.groupBy(tour.slots, 'itemId')
    const itemsAr = _.map(byItemId, items => _.sortBy(items, 'createdAt'))
    return _.sortBy(itemsAr, ar => ar[0].createdAt)
  }, [tour.slots])

  const renderCartItems = () => {
    if (items) {
      const res: any = []
      _.forEach(sortedItems, ar => {
        _.forEach(ar, (tourSlot, i) => {
          const item = _.get(items, tourSlot.itemId)
          if (item) {
            res.push(
              <PanelCartItem
                sdk={sdk}
                key={tourSlot.id}
                item={item}
                index={i}
                quantity={_.size(ar)}
                openProductCard={openProductCard}
                addToRoom={addToRoom}
                onRemove={() => removeItem(tourSlot.id)}
                mattertagId={_.get(mattertags, tourSlot.id)}
                supplierName={
                  item.supplierId && _.get(suppliers, [item.supplierId, 'name'])
                }
              />
            )
          } else {
            console.warn('item of tour slot not found, ts:', tourSlot)
          }
        })
      })
      return res
    }
  }

  const orderTotal = _.sum(
    _.map(
      sortedItems,
      ar => _.get(items, [ar[0].itemId, 'price'], 0) * _.size(ar)
    )
  )

  const resetDesign = () => {
    dbUpdateTourPath(tour.id, 'slots', {})
  }

  return (
    <RightPanelWrapper show width={380}>
      <Flex flex={1} maxH='full' direction='column'>
        <Box pb={8} bg='white' p={4}>
          <Flex align='flex-start' justify={'space-between'}>
            <HStack align='flex-start' spacing={4}>
              <VStack align={'flex-start'}>
                <Heading size='md' color='black'>
                  {tour ? tour.name : 'Do It Yourself'}
                </Heading>
              </VStack>
            </HStack>
            <Button
              size='xs'
              variant={'ghost'}
              color='blue.500'
              onClick={resetDesign}
            >
              Reset design
            </Button>
          </Flex>
        </Box>
        <VStack
          direction='column'
          align={'center'}
          justify='flex-start'
          flex={1}
          h='full'
          overflowX='hidden'
          overflowY={'auto'}
          sx={{ '.divider': { margin: 0 } }}
          divider={
            <Box
              className='divider'
              borderColor={'gray.300'}
              borderBottomWidth={1}
              w='full'
            />
          }
          scrollSnapType={'y proximity'}
        >
          {renderCartItems()}
        </VStack>
        <VStack bg='white' w='full' p={4} spacing={4}>
          <Flex align={'center'} justify='space-between' w='full'>
            <Text fontSize={'lg'} fontWeight='bold'>
              Package Total:
            </Text>
            <Text fontSize={'lg'} fontWeight='bold'>
              {numeral(orderTotal).format('$0,0.00')}
            </Text>
          </Flex>
          <Flex w='full'>
            <Button
              leftIcon={<FontAwesomeIcon icon={faPlus} />}
              w='full'
              // variant={'primary'}
              size='lg'
              colorScheme={'blue'}
              onClick={() => onAddFurnitureClick()}
            >
              Add furniture
            </Button>
          </Flex>
        </VStack>
      </Flex>
    </RightPanelWrapper>
  )
}

export default ProDesignPanel
