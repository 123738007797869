import { FC, useMemo, useState } from 'react'
import _ from 'lodash'
import {
  Box,
  Flex,
  Text,
  VStack,
  HStack,
  Button,
  Input,
  FormControl,
  Tooltip,
  Alert,
  AlertIcon
} from '@chakra-ui/react'
import moment from 'moment'
import { useSelector } from 'model/hooks'
import { OrderT, TourModelT, TourT, TourSlotT } from 'shared/types/model'
import OrderItem from 'pages/order/OrderItem'
import OrderSummary from 'pages/order/OrderSummary'
import { getItemPhotoUrl } from 'shared/utils/itemsUtils'
import { getOrderStatus } from 'utils/orders'
import { updateOrder } from 'controllers/orders'
import orderStatuses from 'shared/constants/orderStatuses'
import NavBar from 'components/NavBar'
import OrderNavBarTitle from 'pages/order/OrderNavBarTitle'
import OrderDelivery from 'pages/order/OrderDelivery'
import OrderInfoSection from 'pages/order/OrderInfoSection'

type Props = {
  order: OrderT
}

const OrderContentPartner: FC<Props> = ({ order }) => {
  const suppliers = useSelector(state => state.suppliers)
  const partner = useSelector(state => _.get(state.partners, order.partnerId))
  const items = useSelector(state => state.items)
  const [deliveryDate, setDeliveryDate] = useState(
    order.partnerDeliveryDate || ''
  )

  console.log('deliveryDate', deliveryDate)

  const orderStatus = getOrderStatus(order, true)

  const itemsIds = _.filter(
    _.uniq(_.map(order.items, (ts: TourSlotT) => ts.itemId)),
    id => order.quantities[id] > 0 && !_.includes(order.partnerHandleItems, id)
  )

  const [selectedItems, setSelectedItems] = useState(itemsIds)

  const partnerTotal = useMemo(() => {
    const prices = _.map(
      order.partnerHandleItems,
      itemId =>
        _.get(items, [itemId, 'price'], 0) * _.get(order.quantities, itemId, 0)
    )
    return _.sum(prices)
  }, [order])

  const upstagerTotal = useMemo(() => {
    const ids =
      orderStatus === orderStatuses.REVIEW
        ? selectedItems
        : order.upstagerHandleItems
    const prices = _.map(ids, itemId => {
      return (
        _.get(items, [itemId, 'price'], 0) * _.get(order.quantities, itemId, 0)
      )
    })
    return _.sum(prices)
  }, [order, selectedItems])

  const tour: TourT | null = useSelector(state =>
    order.tourId ? state.tours[order.tourId] : null
  )

  const tourModel: TourModelT | null = useSelector(state =>
    state.tourModels ? state.tourModels[order.tourModelId] : null
  )

  const toggleItem = (itemId: string, v: boolean) => {
    if (v) {
      setSelectedItems(selectedItems => _.uniq([...selectedItems, itemId]))
    } else {
      setSelectedItems(selectedItems =>
        _.filter(selectedItems, id => id !== itemId)
      )
    }
  }

  const renderItemsPartnerHandles = () => {
    if (order.partnerHandleItems) {
      return (
        <Flex
          bg='white'
          borderWidth={1}
          rounded='xl'
          flex={1}
          boxShadow={'base'}
          direction='column'
        >
          <Box px={4} py={4} borderBottomWidth={1}>
            <Text fontSize={'lg'} color='gray.900' fontWeight={'bold'}>
              {`These items will be handled by ${partner?.name}`}
            </Text>
            <Text fontSize={'sm'} color='gray.600' fontWeight={'normal'}>
              {`Expected delivery date is ${moment(
                order.partnerDeliveryDate
              ).format('ll')}`}
            </Text>
          </Box>
          <VStack align={'flex-start'} w='full' py={4}>
            {_.map(order.partnerHandleItems, (itemId: string) => {
              const item = items && items[itemId]
              if (!item) return null
              const name = _.get(item, 'title', '')
              const description = _.get(item, 'desc', '')
              const price = _.get(item, 'price', 0)
              const quantity = _.get(order.quantities, itemId, 0)
              return (
                <OrderItem
                  key={item.id}
                  name={name}
                  description={description}
                  price={price}
                  quantity={quantity}
                  imageUrl={getItemPhotoUrl(item)}
                  checked={_.includes(selectedItems, itemId)}
                  canSelect={false}
                  supplier={
                    item.supplierId
                      ? _.get(suppliers, [item.supplierId, 'name'])
                      : '-'
                  }
                />
              )
            })}
            <OrderSummary total={partnerTotal} title='Subtotal' size='md' />
          </VStack>
        </Flex>
      )
    } else {
      return (
        <Alert status='warning'>
          <AlertIcon />
          {`${partner?.name} cannot handle items of the order`}
        </Alert>
      )
    }
  }

  const renderOrderItems = () => {
    const canSelect = order.status === orderStatuses.REVIEW
    return (
      <Flex
        bg='white'
        borderWidth={1}
        rounded='xl'
        flex={1}
        boxShadow={'base'}
        direction='column'
      >
        <Box px={4} py={4} borderBottomWidth={1}>
          <Text fontSize={'lg'} color='gray.900' fontWeight={'bold'}>
            {canSelect ? 'Items' : 'You deliver the items'}
          </Text>
          <Text fontSize={'sm'} color='gray.600' fontWeight={'normal'}>
            {canSelect
              ? 'Select items that you are able to deliver'
              : `Expected delivery date is ${moment(
                  order.upstagerDeliveryDate
                ).format('ll')}`}
          </Text>
        </Box>
        <VStack align={'flex-start'} w='full' py={4}>
          {_.map(itemsIds, (itemId: string) => {
            const item = items && items[itemId]
            if (!item) return null
            const name = _.get(item, 'title', '')
            const description = _.get(item, 'desc', '')
            const price = _.get(item, 'price', 0)
            const quantity = _.get(order.quantities, itemId, 0)

            return (
              <OrderItem
                key={item.id}
                name={name}
                description={description}
                price={price}
                quantity={quantity}
                imageUrl={getItemPhotoUrl(item)}
                checked={_.includes(selectedItems, itemId)}
                toggleItem={(v: boolean) => toggleItem(itemId, v)}
                canSelect={canSelect}
                supplier={
                  item.supplierId
                    ? _.get(suppliers, [item.supplierId, 'name'])
                    : '-'
                }
              />
            )
          })}
          <OrderSummary total={upstagerTotal} title='Subtotal' size='md' />
        </VStack>
      </Flex>
    )
  }

  const apply = () => {
    const upd: Partial<OrderT> = {
      upstagerHandleItems: selectedItems,
      upstagerDeliveryDate: deliveryDate,
      status: orderStatuses.WAITING_FOR_PAYMENT
    }
    updateOrder(order.id, _.omitBy(upd, _.isNil))
  }

  const actionsNew = (
    <Flex w='full' justify={'flex-end'}>
      <Box>
        <FormControl>
          <HStack>
            <Box minW='24' lineHeight={1.2}>
              <Text fontSize={'sm'} color='gray.600'>
                Expected delivery date
              </Text>
            </Box>
            <Input
              value={deliveryDate}
              type='date'
              onChange={e => setDeliveryDate(e.target.value)}
              bg='white'
              onAbort={() => setDeliveryDate('')}
            />
            <Tooltip
              label={
                _.isEmpty(selectedItems)
                  ? 'Select at least one item'
                  : 'Set the expected delivery date'
              }
              shouldWrapChildren
            >
              <Button colorScheme='blue' onClick={apply}>
                Confirm
              </Button>
            </Tooltip>
          </HStack>
        </FormControl>
      </Box>
    </Flex>
  )

  const onComplete = () => {
    const upd: Partial<OrderT> = { status: orderStatuses.DONE }
    updateOrder(order.id, upd)
  }

  const renderActions = () => {
    switch (order.status) {
      case orderStatuses.REVIEW:
        return actionsNew
      case orderStatuses.PAID:
      case orderStatuses.DONE_BY_PARTNER:
        return (
          <Button colorScheme={'teal'} onClick={onComplete}>
            Complete
          </Button>
        )
      default:
        return null
    }
  }

  const renderOrderSummary = () => {
    return <OrderSummary total={upstagerTotal + partnerTotal} />
  }

  const renderOrderDelivery = () => {
    if (
      orderStatus === orderStatuses.PAID ||
      orderStatus === orderStatuses.DONE_BY_PARTNER
    ) {
      return <OrderDelivery order={order} />
    }
  }

  return (
    <VStack h='full' w='full'>
      <NavBar
        title={
          <OrderNavBarTitle
            order={order}
            orderStatus={orderStatus}
            tourModel={tourModel}
          />
        }
      >
        {renderActions()}
      </NavBar>
      <Flex
        direction={'column'}
        w='full'
        overflow={'hidden'}
        flex={1}
        align='center'
      >
        <Flex
          pt={2}
          pb={12}
          overflowY={'auto'}
          direction='column'
          w='full'
          align={'center'}
        >
          <VStack spacing={4} maxWidth={'3xl'}>
            <OrderInfoSection
              order={order}
              tourModel={tourModel}
              tour={tour}
              partner={partner}
            />
            {renderItemsPartnerHandles()}
            {renderOrderItems()}
            {renderOrderDelivery()}
            {renderOrderSummary()}
          </VStack>
        </Flex>
      </Flex>
    </VStack>
  )
}

export default OrderContentPartner
