import { DictT, TourModelT } from 'shared/types/model'
import * as types from 'model/actionTypes'

const initialState: DictT<TourModelT> | null = null

export default function (
  state = initialState,
  action: { type: string; tourModels: DictT<TourModelT> }
) {
  switch (action.type) {
    case types.RECEIVE_TOUR_MODELS:
      return action.tourModels
    case types.CLEAR:
    case types.LOGOUT:
      return initialState
    default:
      return state
  }
}
