import orderStatuses from 'shared/constants/orderStatuses'
import { OrderT } from 'types/model'
import _ from 'lodash'

export const getOrderStatus = (order: OrderT, isUpstagerTeam: boolean) => {
  if (
    !_.isUndefined(order.partnerCompleted) &&
    order.status !== orderStatuses.DONE
  ) {
    return isUpstagerTeam ? orderStatuses.DONE_BY_PARTNER : orderStatuses.DONE
  } else {
    return order.status
  }
}

export const statusInfo = {
  [orderStatuses.NEW]: {
    color: 'orange',
    title: 'New'
  },
  [orderStatuses.WAITING_FOR_PAYMENT]: {
    color: 'blackAlpha',
    title: 'Waiting for payment'
  },
  [orderStatuses.REVIEW]: {
    color: 'gray',
    title: 'Under review'
  },
  [orderStatuses.IN_PROGRESS]: {
    color: 'blue',
    title: 'In progress'
  },
  [orderStatuses.DONE]: {
    color: 'blackAlpha',
    title: 'Completed'
  },
  [orderStatuses.CANCELED]: {
    color: 'red',
    title: 'Canceled'
  },
  [orderStatuses.PAID]: {
    color: 'teal',
    title: 'Paid'
  },
  [orderStatuses.DONE_BY_PARTNER]: {
    color: 'cyan',
    title: 'Done by partner'
  }
}
