import { FC, useMemo, useEffect } from 'react'
import _ from 'lodash'
import { Flex, Text, Button, Switch, useToast, Tooltip } from '@chakra-ui/react'
import moment from 'moment'
import { useSelector } from 'model/hooks'
import { TourT, TourModelT } from 'shared/types/model'
import { useMeasure, useCopyToClipboard } from 'react-use'
import BaseTable from 'react-base-table'
import { getAddress } from 'shared/utils/addressUtils'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons'
import { updateTour } from 'controllers/tours'
import config from 'shared/config'

type RowT = {
  id: string
  name: string
  address: string
  enabled: boolean
  itemsAmount: number
  createdAt: number
  tourModelId: string
}

const DesignsContent: FC = () => {
  const tours = useSelector(state => state.tours)
  const navigate = useNavigate()
  const tourModels = useSelector(state => state.tourModels)
  const [state, copyToClipboard] = useCopyToClipboard()
  const toast = useToast()
  const [containerRef, { width, height }] = useMeasure<HTMLDivElement>()

  useEffect(() => {
    if (state.value) {
      toast({
        title: 'Link copied to clipboard',
        description: 'Share the url with your customers',
        status: 'success',
        position: 'top',
        duration: 3000,
        isClosable: true
      })
    }
  }, [state])

  const data = useMemo(() => {
    if (tourModels) {
      const rows: RowT[] = []
      _.forEach(tours, (t: TourT) => {
        const tm: TourModelT = tourModels[t.tourModelId]
        if (_.isNil(tm)) {
          console.warn('tour model does not exist', t.tourModelId, 'tour', t)
        } else {
          const address = getAddress(tm.address)
          const row: RowT = {
            id: t.id,
            name: t.name,
            address,
            enabled: t.enabled ? t.enabled : false,
            itemsAmount: _.size(t.slots),
            createdAt: t.createdAt,
            tourModelId: t.tourModelId
          }
          rows.push(row)
        }
      })
      return _.sortBy(rows, ['timestamp'])
    } else {
      return []
    }
  }, [tours, tourModels])

  const columns = [
    {
      flexGrow: 1,
      key: 'name',
      dataKey: 'name',
      width: 0,
      title: 'Name',
      cellRenderer: ({
        cellData,
        rowData
      }: {
        cellData: string
        rowData: RowT
      }) => (
        <Button
          variant={'link'}
          onClick={() => navigate(`/tour/${rowData.id}`)}
          leftIcon={<FontAwesomeIcon icon={faEdit} />}
          size='xs'
        >
          {cellData}
        </Button>
      )
    },
    {
      flexGrow: 2,
      key: 'address',
      dataKey: 'address',
      width: 0,
      title: 'Address',
      cellRenderer: ({ cellData }: { cellData: string }) => (
        <Text>{cellData}</Text>
      )
    },
    {
      flexGrow: 1,
      key: 'createdAt',
      dataKey: 'createdAt',
      width: 0,
      title: 'Created At',
      cellRenderer: ({ cellData }: { cellData: number }) => (
        <Text>{moment(cellData).format('lll')}</Text>
      )
    },
    {
      flexGrow: 1,
      key: 'itemsAmount',
      dataKey: 'itemsAmount',
      width: 0,
      title: 'Items Amount',
      cellRenderer: ({ cellData }: { cellData: number }) => (
        <Text>{cellData > 0 ? cellData : ''}</Text>
      )
    },
    {
      flexGrow: 1,
      key: 'enabled',
      dataKey: 'enabled',
      width: 0,
      title: 'Is enabled',
      cellRenderer: ({
        cellData,
        rowData
      }: {
        cellData: boolean
        rowData: RowT
      }) => (
        <Tooltip
          shouldWrapChildren
          hasArrow
          bg='red.600'
          label={rowData.itemsAmount > 0 ? undefined : 'Add items first'}
        >
          <Switch
            id='enable-design'
            isChecked={cellData}
            onChange={() => updateTour(rowData.id, { enabled: !cellData })}
            disabled={rowData.itemsAmount === 0}
          />
        </Tooltip>
      )
    },
    {
      flexGrow: 1,
      key: 'tourModelId',
      dataKey: 'tourModelId',
      width: 0,
      title: 'Share',
      cellRenderer: ({
        cellData,
        rowData
      }: {
        cellData: string
        rowData: RowT
      }) => (
        <Tooltip
          shouldWrapChildren
          hasArrow
          bg='red.600'
          label={rowData.enabled ? undefined : 'Enable the design please'}
        >
          <Button
            variant={'link'}
            onClick={() =>
              copyToClipboard(`${config.customerAppUrl}/${cellData}`)
            }
            leftIcon={<FontAwesomeIcon icon={faUpRightFromSquare} />}
            size='xs'
            disabled={!rowData.enabled}
          >
            Share
          </Button>
        </Tooltip>
      )
    }
  ]

  return (
    <Flex flex={1} direction={'row'} grow={1} p={4} pt={2} ref={containerRef}>
      <BaseTable
        data={data}
        width={width}
        height={height}
        columns={columns}
        rowHeight={50}
        ignoreFunctionInColumnCompare={false}
      />
    </Flex>
  )
}

export default DesignsContent
