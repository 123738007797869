import _ from 'lodash'
import { FC, useState, useRef, useMemo } from 'react'

import { useSelector } from 'model/hooks'
import { MpSdk } from 'shared/bundle/sdk'
import config from 'shared/config'
import { Box, Text, Flex } from '@chakra-ui/react'
import Tour from 'shared/components/Tour'
import AmbientLight from 'shared/components/AmbientLight'
import { TourSlotT, ItemT, SlotT, TourT, TourModelT } from 'shared/types/model'
import { Vector3, Vector3Tuple } from 'three'
import TourItem from 'pages/editTour/tourView/TourItem'
import { dbDeleteTourSlot, dbUpdateTourPath } from 'controllers/tours'
import PointLightOnCamera from 'shared/components/PointLightOnCamera'
import ProductCardModal, {
  IProductCardModal
} from 'shared/components/ProductCardModal'
import { generateId } from 'controllers/db'
import RoomShadow from 'shared/components/RoomShadow'
import OutlinePass from 'shared/components/OutlinePass'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import AddingItem from 'shared/components/AddingItem'
import ItemHighlight from 'shared/components/ItemHighlight'
// import RotateControl from 'shared/components/RotateControl'
import UserItemsPanel from 'pages/editTour/tourView/UserItemsPanel'
import CatalogPanel from 'shared/components/CatalogPanel'
import SelectedItemPanel from 'shared/components/SelectedItemPanel'
import AddingItemPanel from 'shared/components/AddingItemPanel'
import { getItemsBySlot } from 'model/selectors/itemsSelector'
import EditTourNavBar from 'pages/editTour/EditTourNavBar'
import { useTitle } from 'react-use'
import { useNavigate } from 'react-router-dom'
import RotateControlSlider from 'shared/components/RotateControlSlider'

type LocationInfoT = {
  cameraPose?: MpSdk.Camera.Pose
  sweep?: MpSdk.Sweep.ObservableSweepData
  rooms?: MpSdk.Room.RoomData[]
}

type Props = {
  tour: TourT
  tourModel: TourModelT
}

const TourView: FC<Props> = ({ tour, tourModel }) => {
  const [sdk, setSdk] = useState<MpSdk | null>(null)
  const [effectComposer, setEffectComposer] = useState<EffectComposer>()
  const items = useSelector(state => state.items)
  const slots = useSelector(state => state.slots)
  const locationRef = useRef<LocationInfoT>({})
  const productCardRef = useRef<IProductCardModal>(null)
  const [addingItemId, setAddingItemId] = useState<string | null>(null)
  const [moving, setMoving] = useState(false)
  const [catalogVisible, setCatalogVisible] = useState(false)
  const mattertags = useSelector(state => state.mattertags)
  const itemsBySlot = useSelector(getItemsBySlot)
  const suppliers = useSelector(state => state.suppliers)
  const [viewMode, setViewMode] = useState<MpSdk.Mode.Mode>(
    'mode.inside' as MpSdk.Mode.Mode.INSIDE
  )
  const navigate = useNavigate()
  const [hoveredItemId, setHoverredItemId] = useState<string | null>(null)

  useTitle(`${tour.name} - UpStager Editor`)

  const showItems = useMemo(() => {
    return (
      viewMode === 'mode.inside' ||
      viewMode === 'mode.floorplan' ||
      viewMode === 'mode.transitioning' ||
      viewMode === 'mode.dollhouse'
    )
  }, [viewMode])

  const tourSlotIdRef = useRef<string | null>(null)
  const [selectedTourSlotId, _setSelectedTourSlotId] = useState<string | null>(
    null
  )

  const toSuppliers = () => {
    navigate('/suppliers')
  }

  const setSelectedTourSlotId = (sId: string | null) => {
    tourSlotIdRef.current = sId
    _setSelectedTourSlotId(sId)
  }

  const onSlotSelect = (tourSlotId: string | null) => {
    const sId = tourSlotIdRef.current
    if (!_.isNil(tourSlotId) && tourSlotId === sId) {
      // console.log('setSelectedSlotId', null)
      setCatalogVisible(false)
      setSelectedTourSlotId(null)
    } else {
      // console.log('setSelectedSlotId', tourSlotId)
      setSelectedTourSlotId(tourSlotId)
      if (_.isNil(tourSlotId)) {
        setCatalogVisible(false)
      }
    }
  }

  const onSdkReady = async (sdk: MpSdk, composer?: EffectComposer) => {
    // console.log('onSdkReady', sdk, composer)
    setEffectComposer(composer)
    setSdk(sdk)

    sdk.Room.current.subscribe(currentRooms => {
      locationRef.current.rooms = currentRooms.rooms
    })
    sdk.Sweep.current.subscribe(function (currentSweep) {
      // console.log('rooms', currentSweep)
      locationRef.current.sweep = currentSweep
    })
    sdk.Camera.pose.subscribe(function (pose) {
      if (pose.mode !== locationRef.current.cameraPose?.mode) {
        setViewMode(pose.mode)
      }
      locationRef.current.cameraPose = { ...pose }
    })
  }

  // const DEFAULT_TITLE = 'Upstager'
  // useEffect(() => {
  //   if (!_.isNil(tourModel) && _.isEqual(DEFAULT_TITLE, document.title)) {
  //     document.title = `${DEFAULT_TITLE} – ${tourModel.description}`
  //   }

  //   return () => {
  //     document.title = DEFAULT_TITLE
  //   }
  // }, [tourModel])

  const onMoveComplete = async (p: Vector3) => {
    if (tourSlotIdRef.current) {
      const itemPosition: Vector3Tuple = [
        _.round(p.x, 3),
        _.round(p.y, 3),
        _.round(p.z, 3)
      ]
      console.log('update user item, set position', itemPosition)
      await dbUpdateTourPath(
        tour.id,
        `slots.${tourSlotIdRef.current}.position`,
        itemPosition
      )
      setMoving(false)
    }
  }

  const renderTourSlots = () => {
    if (sdk && items && showItems) {
      // console.log('renderTourSlots', tour.slots)
      return _.map(tour.slots, (s: TourSlotT) => {
        // console.log('renderTourSlot', s)
        const itemId = s.itemId
        const item: ItemT | undefined = items[itemId]
        if (item) {
          const slot: SlotT | undefined = _.get(slots, item.kind)
          if (item && item.model && slot) {
            return (
              <TourItem
                key={s.id + itemId + item.kind}
                tourId={'do_it_yourself'}
                itemId={s.itemId}
                tourSlotId={s.id}
                model={item.model}
                slotSize={slot.size}
                sdk={sdk}
                slotPosition={new Vector3().fromArray(s.position)}
                onClick={onSlotSelect}
                isSelected={selectedTourSlotId === s.id}
                rotation={s.rotation}
                canMove={moving && s.id === selectedTourSlotId}
                onMove={onMoveComplete}
                mattertagId={mattertags[s.id]}
                viewMode={viewMode}
                isHovered={s.id + '_' + itemId === hoveredItemId}
              />
            )
          }
        }
      })
    }
  }

  const onAddItem = (itemId: string) => {
    const sId = tourSlotIdRef.current
    if (sId) {
      dbUpdateTourPath(tour.id, `slots.${sId}.itemId`, itemId)
    } else {
      setAddingItemId(itemId)
    }
  }

  const openProductCard = (itemId: string) => {
    const item = items && items[itemId]
    if (item) {
      const supplier = item.supplierId && _.get(suppliers, item.supplierId)
      productCardRef.current?.open(item, supplier)
    }
  }

  const renderCatalogPanel = () => {
    let selectedItemId = null
    const tourSlotId = tourSlotIdRef.current
    if (tourSlotId) {
      const ts: TourSlotT = tour.slots[tourSlotId]
      if (ts) {
        selectedItemId = ts.itemId
      }
    }
    if (slots) {
      return (
        <CatalogPanel
          closeCatalogClick={() => setCatalogVisible(false)}
          addToRoom={onAddItem}
          visible={catalogVisible && !addingItemId}
          selectedItemId={selectedItemId}
          openProductCard={openProductCard}
          itemsBySlot={itemsBySlot}
          slots={slots}
          suppliers={suppliers}
          isEditor
          toSuppliers={toSuppliers}
        />
      )
    } else {
      console.warn('renderCatalogPanel: slots are empty')
    }
  }

  const removeTourSlot = (tourSlot: TourSlotT) => {
    dbDeleteTourSlot(tour.id, tourSlot.id)
  }

  const removeSelectedItem = () => {
    // console.log('remove selected item')
    const tourSlotId = tourSlotIdRef.current
    if (tourSlotId) {
      const tourSlot: TourSlotT = tour.slots[tourSlotId]
      setSelectedTourSlotId(null)
      removeTourSlot(tourSlot)
    } else {
      console.warn('removeSelectedItem tourSlotId is undefined')
    }
  }

  const removeItem = (tourSlotId: string) => {
    const tourSlot: TourSlotT = tour.slots[tourSlotId]
    if (tourSlot) {
      if (tourSlotId === tourSlotIdRef.current) {
        setSelectedTourSlotId(null)
      }
      removeTourSlot(tourSlot)
    } else {
      console.warn('removeItem tourSlot not found')
    }
  }

  const renderSelectedItemPanel = () => {
    if (selectedTourSlotId) {
      const ts: TourSlotT = tour.slots[selectedTourSlotId]
      if (ts && items) {
        const item: ItemT | undefined = items[ts.itemId]
        if (item) {
          return (
            <SelectedItemPanel
              tourSlotId={selectedTourSlotId}
              item={item}
              removeItem={removeSelectedItem}
              cancelSelection={() => onSlotSelect(null)}
              onReplaceClick={() => setCatalogVisible(true)}
              catalogVisible={catalogVisible}
              openProductCard={() => openProductCard(item.id)}
              supplier={item.supplierId && suppliers[item.supplierId]}
            />
          )
        }
      }
    }
  }

  const renderPanel = () => {
    if (catalogVisible || selectedTourSlotId || addingItemId || _.isNil(sdk)) {
      return null
    } else {
      return (
        <UserItemsPanel
          sdk={sdk}
          onAddFurnitureClick={() => setCatalogVisible(true)}
          openProductCard={openProductCard}
          addToRoom={onAddItem}
          removeItem={removeItem}
          tour={tour}
        />
      )
    }
  }

  const applyAddingItem = (p: Vector3) => {
    // console.log('applyAddingItem', p, moving, selectedTourSlotId, addingItemId)
    // console.log('applyAddingItem', tourSlotIdRef.current)
    if (addingItemId) {
      const id = generateId()
      const ts: TourSlotT = {
        id,
        itemId: addingItemId,
        position: [_.round(p.x, 3), _.round(p.y, 3), _.round(p.z, 3)],
        createdAt: _.now()
      }
      dbUpdateTourPath(tour.id, `slots.${ts.id}`, ts)
      setAddingItemId(null)
      onSlotSelect(null)
      setCatalogVisible(false)
    }
  }

  const renderAddingItem = () => {
    if (sdk && addingItemId) {
      const item = _.get(items, addingItemId)
      if (item && item.kind) {
        const slot = _.get(slots, item.kind)
        if (slot) {
          return (
            <AddingItem
              key={addingItemId}
              itemId={addingItemId}
              item={item}
              slot={slot}
              sdk={sdk}
              onAddItem={applyAddingItem}
              rotation={0}
              viewMode={viewMode}
            />
          )
        }
      }
    }
  }

  const renderItemHighlight = () => {
    const enabled = false
    if (enabled && sdk && effectComposer) {
      let itemId = null
      if (selectedTourSlotId) {
        const ts: TourSlotT = tour.slots[selectedTourSlotId]
        if (ts) {
          itemId = selectedTourSlotId + '_' + ts.itemId
        }
      }
      return (
        <ItemHighlight
          sdk={sdk}
          effectComposer={effectComposer}
          itemId={itemId}
        />
      )
    }
  }

  const onObjectsHover = (ids: string[]) => {
    setHoverredItemId(_.get(ids, 0, null))
  }

  const renderOutlinePass = () => {
    const showOutlinePass = true
    if (sdk && effectComposer && showOutlinePass) {
      let itemId = null
      if (selectedTourSlotId) {
        const ts: TourSlotT = tour.slots[selectedTourSlotId]
        if (ts) {
          itemId = selectedTourSlotId + '_' + ts.itemId
        }
      }
      return (
        <OutlinePass
          sdk={sdk}
          effectComposer={effectComposer}
          excludeItem={itemId}
          onHover={onObjectsHover}
        />
      )
    }
  }

  const onRotated = (v: number) => {
    // console.log('onRotated', v, selectedTourSlotId)
    if (tourSlotIdRef.current) {
      dbUpdateTourPath(tour.id, `slots.${tourSlotIdRef.current}.rotation`, v)
    }
  }

  const renderAddingItemTopBar = () => {
    if (addingItemId) {
      return <AddingItemPanel onCancel={() => setAddingItemId(null)} />
    }
  }

  // const renderRotateControl = () => {
  //   if (sdk) {
  //     let itemKey = null
  //     let size
  //     let modelRotate = 0
  //     if (selectedTourSlotId && !moving && viewMode === sdk.Mode.Mode.INSIDE) {
  //       const ts: TourSlotT = tour.slots[selectedTourSlotId]
  //       if (ts) {
  //         const item = items && items[ts.itemId]
  //         if (item) {
  //           itemKey = 'box_' + selectedTourSlotId + '_' + ts.itemId
  //           const slot: SlotT | null = slots && slots[item.kind]
  //           modelRotate = _.get(item, ['model', 'rotation', 1], 0)
  //           if (slot) {
  //             size = _.max([slot.size[0], slot.size[2]])
  //           }
  //         }
  //       }
  //     }
  //     return (
  //       <RotateControl
  //         sdk={sdk}
  //         itemKey={itemKey}
  //         size={size}
  //         onRotated={onRotated}
  //         modelRotate={modelRotate}
  //       />
  //     )
  //   }
  // }

  const renderRotateControlSlider = () => {
    if (sdk && selectedTourSlotId) {
      const ts: TourSlotT = tour.slots[selectedTourSlotId]
      if (ts) {
        return (
          <RotateControlSlider
            onRotated={onRotated}
            modelRotate={ts.rotation || 0}
          />
        )
      }
    }
  }

  // const onStartMoving = () => {
  //   setMoving(true)
  // }

  // const renderMoveButton = () => {
  //   const showMoveButton = false
  //   if (sdk && showMoveButton) {
  //     let itemKey = null
  //     let size = 1
  //     if (selectedTourSlotId && !moving) {
  //       const ts: TourSlotT = tour.slots[selectedTourSlotId]
  //       if (ts) {
  //         console.log('renderMoveButton ts.position', ts.position)
  //         const item = items && items[ts.itemId]
  //         if (item) {
  //           // console.log('renderMoveButton item', item)
  //           itemKey = 'move_' + selectedTourSlotId + '_' + ts.itemId
  //           const slot: SlotT | null = slots && slots[item.kind]
  //           // modelRotate = _.get(item, ['model', 'rotation', 1], 0)
  //           if (slot) {
  //             size = _.max([slot.size[0], slot.size[2]]) || 1
  //           }
  //         }
  //         return (
  //           <MoveButton
  //             key={itemKey}
  //             sdk={sdk}
  //             itemKey={itemKey}
  //             position={
  //               new Vector3(ts.position[0], ts.position[1], ts.position[2])
  //             }
  //             size={size}
  //             initialPose={locationRef.current.cameraPose}
  //             onStartMoving={onStartMoving}
  //             viewMode={viewMode}
  //           />
  //         )
  //       }
  //     }
  //   }
  // }

  if (tourModel) {
    return (
      <Box w='full' h='full' position='relative'>
        <EditTourNavBar visible={!selectedTourSlotId} />
        <Tour
          modelId={tourModel.modelId}
          applicationKey={config.applicationKey}
          onSdkReady={onSdkReady}
          hideFloorPlan={false}
          // hideMattertags={false}
          // hideDollHouse
          // hideGuidedTour
          // hideHighlightReels
          // hideFloorSwitch
          // hidePins={false}
          quickStart={false}
        />
        {sdk && <RoomShadow sdk={sdk} />}
        {sdk && <PointLightOnCamera sdk={sdk} />}
        {sdk && <AmbientLight sdk={sdk} />}
        {renderOutlinePass()}

        {renderTourSlots()}
        {renderAddingItem()}
        {renderItemHighlight()}
        <Flex position='absolute' top='0' right='0'>
          {renderPanel()}
          {renderCatalogPanel()}
          {renderAddingItemTopBar()}
        </Flex>
        {renderSelectedItemPanel()}
        <ProductCardModal ref={productCardRef} />
        {/* {renderRotateControl()} */}
        {renderRotateControlSlider()}
        {/* {renderMoveButton()} */}
      </Box>
    )
  } else {
    return <Text>loading tour...</Text>
  }
}

export default TourView
