import {
  onSnapshot,
  collection,
  query,
  setDoc,
  doc,
  updateDoc,
  deleteField,
  where
} from 'firebase/firestore'
import _ from 'lodash'

import { db } from 'controllers/db'
import store from 'model/store'
import { addListener } from 'controllers/listeners'
import { receiveTours } from 'model/actions/toursAC'
import { TourSlotT, TourT } from 'shared/types/model'

export const fetchTours = (userId: string) => {
  try {
    console.log('fetch tours', userId)
    const q = query(collection(db, 'tours'), where('partnerId', '==', userId))
    const unsubscribe = onSnapshot(
      q,
      sn => {
        const res = {}
        sn.forEach(doc => {
          const p = doc.data()
          _.set(res, doc.id, p)
        })
        console.log('tours received, amount', _.size(res))
        store.dispatch(receiveTours(res))
      },
      err => {
        console.log(`fetchProjects error: ${err}`)
      }
    )
    addListener('projects', unsubscribe)
  } catch (e) {
    console.log('fetchProjects error', e)
  }
}

export const createTour = async (tour: TourT) => {
  console.log('createTour', tour)
  const ref = doc(db, `tours/${tour.id}`)
  setDoc(ref, _.omitBy(tour, _.isNil))
}

export const dbUpdateTourSlot = async (
  tourId: string,
  tourSlotId: string,
  params: Partial<TourSlotT>
) => {
  const ref = doc(db, `tours/${tourId}`)
  const update = {
    [`slots.${tourSlotId}`]: params
  }
  await updateDoc(ref, update)
}

export const dbDeleteTourSlot = async (tourId: string, tourSlotId: string) => {
  const ref = doc(db, `tours/${tourId}`)
  const update = {
    [`slots.${tourSlotId}`]: deleteField()
  }
  await updateDoc(ref, update)
}

export const dbDeleteAllItems = async (tourId: string) => {
  const ref = doc(db, `tours/${tourId}`)
  const update = {
    slots: deleteField()
  }
  await updateDoc(ref, update)
}

export const dbUpdateTourPath = async (
  tourId: string,
  path: string,
  value: any
) => {
  const ref = doc(db, `tours/${tourId}`)
  const update = {
    [path]: value
  }
  await updateDoc(ref, update)
}

// export const addItemToTour = async (
//   tourId: string,
//   slotId: string,
//   itemId: string,
//   position: Vector3,
//   rotation?: Vector3
// ) => {
//   const ref = doc(db, `tours/${tourId}`)
//   const update = {
//     slots: arrayUnion({
//       id: slotId,
//       itemId,
//       position: { ...position },
//       rotation: { ...rotation }
//     })
//   }
//   await updateDoc(ref, update)
// }

export const updateTour = (tourId: string, update: any) => {
  const ref = doc(db, `tours/${tourId}`)
  updateDoc(ref, update)
}
