import { useEffect, useRef, FC } from 'react'

import { MpSdk, Scene } from 'shared/bundle/sdk'
import { outlinePassType } from 'shared/components/matterport/MOutlinePass'
import { Vector2 } from 'three'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'

type OutlinePassProps = {
  sdk: MpSdk
  effectComposer: EffectComposer | null
  excludeItem: string | null
  onHover: (ids: string[]) => void
}

const OutlinePass: FC<OutlinePassProps> = ({
  sdk,
  effectComposer,
  excludeItem,
  onHover
}) => {
  const nodeRef = useRef<MpSdk.Scene.INode | null>(null)
  const compRef = useRef<MpSdk.Scene.IComponent | null>(null)
  const poseRef = useRef<MpSdk.Camera.Pose>()

  useEffect(() => {
    const c = compRef.current
    if (c && c.inputs) {
      c.inputs.excludeItem = excludeItem
    }
  }, [excludeItem])

  useEffect(() => {
    const run = async () => {
      const sceneObjs: Array<MpSdk.Scene.IObject> =
        await sdk.Scene.createObjects(1)
      const sceneObj = sceneObjs[0]
      const node = sceneObj.addNode()
      const outlineComp = node.addComponent(outlinePassType, {
        effectComposer,
        excludeItem
      })

      compRef.current = outlineComp

      const showcase = document.getElementById('showcase')
      if (showcase) {
        const showcaseSize = {
          w: showcase.clientWidth,
          h: showcase.clientHeight
        }

        sdk.Camera.pose.subscribe(pose => {
          poseRef.current = pose
        })

        sdk.Pointer.intersection.subscribe(function (intersectionData) {
          if (compRef.current && compRef.current.inputs) {
            // console.log('pointer intersection:', intersectionData)
            const screenPos = sdk.Conversion.worldToScreen(
              intersectionData.position,
              poseRef.current,
              showcaseSize
            )
            const mouse = new Vector2()
            mouse.x = (Math.abs(screenPos.x) / showcaseSize.w) * 2 - 1
            mouse.y = -(Math.abs(screenPos.y) / showcaseSize.h) * 2 + 1
            // console.log('screenPos', screenPos)
            outlineComp.inputs.position = mouse
            // compRef.current.inputs.position = intersectionData.position
            // const p = intersectionData.position
            // compRef.current.inputs.cameraPosition = new Vector3(p.x, 30, p.z)
            // console.log('Intersection normal:', intersectionData.normal)
          }
        })
      }
      const evPath = sceneObj.addPath({
        id: 'outline_hover',
        type: 'output' as Scene.PathType.OUTPUT,
        node,
        component: outlineComp,
        property: 'selectedObjects'
      })
      const spy = {
        path: evPath,
        onEvent: (ev: any) => {
          // console.log('outline_hover event', ev)
          onHover(ev)
        }
      }
      sceneObj.spyOnEvent(spy)

      node.start()
    }
    run()
    return () => {
      const node = nodeRef.current
      node?.stop()
    }
  }, [sdk.Scene])

  return null
}

export default OutlinePass
