import { RootState } from 'model/rootReducer'
import { createSelector } from '@reduxjs/toolkit'
import _ from 'lodash'
import { SettingsT } from 'types/model'

export const getItems = (state: RootState) => state.items
export const getTours = (state: RootState) => state.tours
export const getSlots = (state: RootState) => state.slots
export const getUser = (state: RootState) => state.user
export const getSettings = (state: RootState) => state.settings
export const getTourModels = (state: RootState) => state.tourModels

export const getIsUpstagerTeam = createSelector(
  [getSettings, getUser],
  (settings: SettingsT, user) => {
    if (settings && user) {
      return settings.defaultPartnerId === user.id
    } else {
      return false
    }
  }
)

export const getAppLoaded = createSelector(
  [getItems, getTours, getSlots, getUser, getTourModels],
  (items, tours, slots, user, tourModels) => {
    const lst = [items, tours, slots, user, tourModels]
    const doneAmount = _.reduce(
      lst,
      (res, elt) => {
        return _.isNil(elt) ? res : res + 1
      },
      0
    )
    return doneAmount === _.size(lst) ? null : (doneAmount / _.size(lst)) * 100
  }
)
