import { FC } from 'react'
import { VStack, Flex, Spinner } from '@chakra-ui/react'
import OrderContent from 'pages/order/OrderContent'
import { useParams } from 'react-router-dom'
import { useSelector } from 'model/hooks'
import { OrderT } from 'shared/types/model'
import _ from 'lodash'
import { getIsUpstagerTeam } from 'model/selectors/base'
import OrderContentPartner from 'pages/order/OrderContentPartner'

const Order: FC = () => {
  const { orderId } = useParams()
  const isUpstagerTeam = useSelector(getIsUpstagerTeam)
  const order: OrderT | null = useSelector(state =>
    orderId ? _.get(state.orders, orderId) : null
  )

  const renderLoding = (
    <Flex justifyContent='center' align='center' py={12}>
      <Spinner />
    </Flex>
  )

  const renderOrderContent = () => {
    if (order) {
      if (isUpstagerTeam && order?.handledByUpstager) {
        return <OrderContentPartner order={order} />
      } else {
        return <OrderContent order={order} />
      }
    } else {
      return renderLoding
    }
  }

  // const navBarTitle = (
  //   <HStack>
  //     <Button
  //       leftIcon={<FontAwesomeIcon icon={faChevronLeft} />}
  //       variant='outline'
  //       size='sm'
  //       onClick={() => navigate('/orders')}
  //     >
  //       back
  //     </Button>
  //   </HStack>
  // )

  return (
    <VStack h='full' w='full'>
      {/* <NavBar title={navBarTitle} /> */}
      {/* <Flex
        direction={'column'}
        w='full'
        overflow={'hidden'}
        flex={1}
        align='center'
      > */}
      {renderOrderContent()}
      {/* </Flex> */}
    </VStack>
  )
}

export default Order
