import { FC, useEffect, useState, useMemo, useRef } from 'react'
import _ from 'lodash'
import { MpSdk, Mode } from 'shared/bundle/sdk'
import Item from 'shared/components/Item'
import { Vector3, Vector3Tuple } from 'three'
import SceneClickListener from 'shared/components/SceneClickListener'
import { ItemT, SlotT } from 'shared/types/model'
// import ModelIntersection from 'shared/components/ModelIntesection'

type Props = {
  itemId: string
  item: ItemT
  slot: SlotT
  sdk: MpSdk
  onAddItem: (p: Vector3) => void
  rotation?: number
  viewMode: Mode.Mode
}

const AddingItem: FC<Props> = ({
  itemId,
  sdk,
  onAddItem,
  item,
  slot,
  rotation = 0,
  viewMode
}) => {
  const poseRef = useRef<MpSdk.Camera.Pose>()
  const [slotPosition, setSlotPosition] = useState<Vector3 | undefined>()
  const slotPositionRef = useRef(slotPosition)
  const [sceneObject, setSceneObject] = useState<MpSdk.Scene.IObject | null>(
    null
  )
  const uniqKey = 'add_item_' + itemId
  const model = _.get(item, 'model')
  const viewModeRef = useRef(viewMode)

  useEffect(() => {
    viewModeRef.current = viewMode
  }, [viewMode])

  useEffect(() => {
    const run = async () => {
      const [so]: Array<MpSdk.Scene.IObject> = await sdk.Scene.createObjects(1)
      setSceneObject(so)
    }
    run()
    return () => sceneObject?.stop()
  }, [])

  const offsetV3 = useMemo(() => {
    const model = item?.model
    const position = _.get(model, 'offset', [0, 0, 0])
    const v3 = new Vector3()
    v3.fromArray(position)
    return v3
  }, [item])

  const modelRotation = useMemo(() => {
    const model = item?.model
    // const res: Vector3Tuple = _.get(model, 'rotation', [0, 0, 0])
    const mr = _.get(model, 'rotation', [0, 0, 0])
    const rotationAxis = _.get(model, 'rotationAxis', 1)
    const res: Vector3Tuple = [mr[0], mr[1], mr[2]]
    res[rotationAxis] = res[rotationAxis] + rotation
    return res
  }, [item, rotation])

  useEffect(() => {
    sdk.Camera.pose.subscribe(function (pose) {
      poseRef.current = pose
    })
  }, [])

  const maxS = useMemo(() => {
    return _.max([slot.size[0], slot.size[2]]) || 1
  }, [slot])

  const multK = useMemo(() => {
    let res = 1.2
    if (maxS) {
      res = (maxS / 2) * 1.5
    }
    return res
  }, [maxS])

  const updateSlotPosition = (p: Vector3) => {
    setSlotPosition(p)
    slotPositionRef.current = p
  }

  useEffect(() => {
    const unsubscribe = sdk.Pointer.intersection.subscribe(
      (intersectionData: MpSdk.Pointer.Intersection) => {
        if (poseRef.current) {
          switch (viewModeRef.current) {
            case sdk.Mode.Mode.INSIDE: {
              const intersP = new Vector3(
                intersectionData.position.x,
                intersectionData.position.y,
                intersectionData.position.z
              )
              const v = new Vector3(
                poseRef.current.position.x,
                intersectionData.position.y,
                poseRef.current.position.z
              )
              // console.log('cameraFloorPosition orig', v)
              v.sub(intersP)
              // console.log('cameraFloorPosition after sub', v)
              v.normalize()
              // console.log('cameraFloorPosition after normalization', v)
              // console.log('multK')
              v.multiplyScalar(multK)
              intersP.sub(v)
              updateSlotPosition(intersP)
              break
            }
            case sdk.Mode.Mode.FLOORPLAN: {
              // console.log('intersectionData', intersectionData)
              // console.log('poseRef.current', poseRef.current)
              const intersP = new Vector3(
                intersectionData.position.x,
                intersectionData.position.y,
                intersectionData.position.z - maxS / 2
              )
              console.log('intersP', intersP, 'object', intersectionData.object)
              updateSlotPosition(intersP)
              break
            }
          }
        }
      }
    )
    return () => unsubscribe.cancel()
  }, [])

  // const onSceneClick = useCallback(() => {
  //   console.log('onSceneClick', slotPosition)
  //   if (slotPosition) {
  //     console.log('call onAddItem')
  //     onAddItem(slotPosition)
  //   }
  // }, [slotPosition, onAddItem])

  const sceneClickListener = () => {
    // console.log('slot position', slotPosition)
    if (slotPositionRef.current) {
      onAddItem(slotPositionRef.current)
    }
  }

  if (model && sceneObject) {
    return (
      <>
        <Item
          key={uniqKey}
          id={uniqKey}
          sdk={sdk}
          url={model.glb}
          scale={model.scale}
          position={slotPosition}
          offset={offsetV3}
          rotation={modelRotation}
          // onClick={onSlotClick}
          sceneObject={sceneObject}
          showLoadingIndicator={true}
        />
        <SceneClickListener sdk={sdk} onClick={sceneClickListener} />
        {/* <ModelIntersection
          sdk={sdk}
          enabled={viewMode === sdk.Mode.Mode.FLOORPLAN}
        /> */}
      </>
    )
  } else {
    return null
  }
}

export default AddingItem
