import { FC } from 'react'
import { Tag } from '@chakra-ui/react'
import { statusInfo } from 'utils/orders'

type Props = {
  status: string
  size?: string
}

const OrderStatusTag: FC<Props> = ({ status, size = 'md' }) => {
  return (
    <Tag size={size} variant='solid' colorScheme={statusInfo[status].color}>
      {statusInfo[status].title}
    </Tag>
  )
}

export default OrderStatusTag
