import { FC, useState, useMemo, useRef, LegacyRef } from 'react'
import {
  VStack,
  HStack,
  Image,
  Box,
  Text,
  Flex,
  Alert,
  AlertIcon
} from '@chakra-ui/react'
import _ from 'lodash'
import { ItemT } from 'shared/types/model'
import ModelViewer from 'shared/components/ModelViewer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faChevronLeft } from '@fortawesome/pro-light-svg-icons'
import { useScroll, useMeasure } from 'react-use'

type Props = {
  item: ItemT
}

const ItemPhotos: FC<Props> = ({ item }) => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const { x } = useScroll(scrollRef)
  const [wrapperRef, wrapperParams] = useMeasure()
  const [itemsContainerRef, itemsContainerParams] = useMeasure()
  const [currentPhoto, setCurrentPhoto] = useState<number | null>(0)
  const photos = useMemo(() => {
    return _.sortBy(item.photos, 'createdAt')
  }, [item.photos])

  const renderPhoto = () => {
    if (!_.isNil(currentPhoto)) {
      const ph = photos[currentPhoto]
      return (
        <Box w='lg' h='lg'>
          <Image w='full' h='full' src={ph.url} rounded='base' />
        </Box>
      )
    } else {
      return <ModelViewer model={item.model} />
    }
  }

  const imgW = 87

  const scrollLeft = () => {
    if (x > 0) {
      const toPosition = _.max([x - imgW, 0])
      scrollRef.current?.scrollTo({ left: toPosition, behavior: 'smooth' })
    }
  }

  const scrollRight = () => {
    const toPosition = x + imgW
    scrollRef.current?.scrollTo({ left: toPosition, behavior: 'smooth' })
  }

  const arrowsNeeded = useMemo(() => {
    return _.size(photos) > 4
  }, [photos])

  const renderList = () => {
    const leftDisabled = x === 0
    const rightDisabled =
      itemsContainerParams.width - wrapperParams.width - x < 2
    return (
      <HStack
        align={'flex-start'}
        sx={{
          '#items_img_container::-webkit-scrollbar': { display: 'none' },
          '#items_img_container': {
            msOverflowStyle: 'none',
            scrollbarWidth: 'none'
          }
        }}
      >
        {arrowsNeeded && (
          <Flex
            h='full'
            align={'center'}
            color='gray.600'
            as={leftDisabled ? undefined : 'button'}
            opacity={leftDisabled ? 0.5 : 1}
            onClick={scrollLeft}
          >
            <FontAwesomeIcon icon={faChevronLeft} size='2x' />
          </Flex>
        )}
        <Box
          maxW='458px'
          overflow={'hidden'}
          ref={wrapperRef as LegacyRef<HTMLDivElement>}
        >
          <Flex ref={scrollRef} id={'items_img_container'} overflowX='auto'>
            <HStack ref={itemsContainerRef as LegacyRef<HTMLDivElement>}>
              <Flex
                shrink={0}
                boxSize={'20'}
                as='button'
                onClick={() => setCurrentPhoto(null)}
                rounded='base'
                borderWidth={1}
                borderColor={'gray.300'}
                opacity={currentPhoto === null ? 1 : 0.5}
                _hover={{ opacity: 1 }}
                align='center'
                justify={'center'}
                boxShadow={currentPhoto === null ? 'base' : undefined}
              >
                <Text fontSize={'2xl'} fontWeight='black' color='gray.700'>
                  3D
                </Text>
              </Flex>
              {_.map(photos, (f, i) => {
                return (
                  <Flex
                    key={f.id}
                    shrink={0}
                    w={20}
                    h={20}
                    as='button'
                    onClick={() => setCurrentPhoto(i)}
                    opacity={currentPhoto === i ? 1 : 0.5}
                    _hover={{ opacity: 1 }}
                    rounded='base'
                    borderWidth={1}
                    borderColor={'gray.300'}
                    boxShadow={currentPhoto === i ? 'base' : undefined}
                  >
                    <Image
                      w='full'
                      h='full'
                      src={f.thumbnailUrl}
                      rounded='base'
                    />
                  </Flex>
                )
              })}
            </HStack>
          </Flex>
        </Box>
        {arrowsNeeded && (
          <Flex
            h='full'
            align={'center'}
            color='gray.600'
            onClick={scrollRight}
            as={rightDisabled ? undefined : 'button'}
            opacity={rightDisabled ? 0.5 : 1}
          >
            <FontAwesomeIcon icon={faChevronRight} size='2x' />
          </Flex>
        )}
      </HStack>
    )
  }

  const renderNote = () => {
    return (
      <Alert status='info' px={0} py={0} bg='transparent' mr={'28'}>
        <AlertIcon pr={0} />
        <Text fontSize={'xs'} lineHeight='1.2' color={'gray.600'}>
          2d photos are more accurate than 3d items in the tour. <br />
          If there is a difference in appearance please refer to the 2d photos
        </Text>
      </Alert>
    )
  }

  return (
    <VStack spacing={6} align='flex-start'>
      {renderPhoto()}
      {renderList()}
      {renderNote()}
    </VStack>
  )
}

export default ItemPhotos
