import { Box } from '@chakra-ui/react'
import TourView from 'pages/editTour/TourView'
import { useSelector } from 'model/hooks'
import { useParams } from 'react-router-dom'
import _ from 'lodash'

const EditTour = () => {
  const { tourId } = useParams()
  const tour = useSelector(state => _.get(state, ['tours', tourId || '']))
  const tourModels = useSelector(state => state.tourModels)

  const renderTourView = () => {
    if (tour && tourModels) {
      const tourModel = tourModels[tour.tourModelId]
      if (tourModel) {
        return <TourView key={tour.id} tour={tour} tourModel={tourModel} />
      }
    }
  }

  return (
    <Box position='relative' w='full' h='full'>
      {renderTourView()}
    </Box>
  )
}

export default EditTour
