import { SceneComponent } from 'shared/components/SceneComponent'
import { MpSdk } from 'shared/bundle/sdk'
import {
  Object3D,
  Raycaster,
  // Vector2,
  Event,
  Intersection,
  Vector3
} from 'three'
import _ from 'lodash'

export interface IMModelIntersectionInputs extends Record<string, unknown> {
  enabled: boolean
  cameraPosition: Vector3
}

class MModelIntersection extends SceneComponent {
  private root: Object3D | null = null
  private raycaster: Raycaster | null = null
  private roomObjects: Object3D[] = []
  private prevIntersects: Intersection<Object3D<Event>>[] | undefined
  private rayDirection: Vector3 = new Vector3(0, -1, 0)

  inputs: IMModelIntersectionInputs = {
    enabled: false,
    cameraPosition: new Vector3()
  }

  onInputsUpdated (oldInputs: IMModelIntersectionInputs) {
    // if (oldInputs.enabled !== this.inputs.enabled) {
    //   const renderer = this.context.renderer
    //   if (this.inputs.enabled) {
    //     renderer.domElement.addEventListener('pointermove', this.onPointerMove)
    //   } else {
    //     renderer.domElement.removeEventListener(
    //       'pointermove',
    //       this.onPointerMove
    //     )
    //   }
    // }
    if (oldInputs.cameraPosition !== this.inputs.cameraPosition) {
      console.log('this.inputs.cameraPosition', this.inputs.cameraPosition)
      this.raycaster?.set(this.inputs.cameraPosition, this.rayDirection)
      const intersects = this.raycaster?.intersectObjects(
        this.context.scene.children,
        true
      )
      console.log('intersects', intersects)
    }
  }

  private getFurnitureMain = (obj: Object3D<Event>): Object3D<Event> | null => {
    const isFurnitureMain = _.get(obj, 'userData.isFurnitureMain', false)
    if (isFurnitureMain) {
      if (this.inputs.excludeItem && this.inputs.excludeItem === obj.name) {
        return null
      } else {
        // console.log('furniture main', obj.name, obj.userData)
        return obj
      }
    } else if (obj && obj.parent) {
      return this.getFurnitureMain(obj.parent)
    } else {
      return null
    }
  }

  // onPointerMove = (event: PointerEvent) => {
  // if (event.isPrimary === false) return
  // const mouse = new Vector2()
  // // const domElement = this.context.renderer.domElement
  // mouse.x = (event.clientX / window.innerWidth) * 2 - 1
  // mouse.y = 1 - (event.clientY / window.innerHeight) * 2
  // console.log('mouse', mouse)
  // this.raycaster?.setFromCamera(mouse, this.context.camera)

  // const intersects = this.raycaster?.intersectObjects(this.roomObjects, true)
  // console.log('intersects', intersects)
  // if (!_.isEqual(intersects, this.prevIntersects)) {
  //   this.prevIntersects = intersects
  //   console.log('intersects', intersects)
  // }
  // }

  onInit () {
    console.log('%MModelIntersection onInit', 'color: red;')
    // console.log('this.context.scene', this.context.scene)
    const THREE = this.context.three
    // const renderer = this.context.renderer
    this.root = new THREE.Object3D()
    this.outputs.objectRoot = this.root
    this.outputs.collider = this.root
    this.raycaster = new this.context.three.Raycaster()
    this.raycaster.far = 50
    this.raycaster.near = 30
    _.forEach(this.context.scene.children, (ch: any) => {
      if (_.startsWith(ch.name, 'ModelMesh')) {
        this.roomObjects.push(ch)
      }
    })
    // if (this.inputs.enabled) {
    //   renderer.domElement.addEventListener('pointermove', this.onPointerMove)
    // }
  }

  onDestroy () {
    // const renderer = this.context.renderer
    // renderer.domElement.removeEventListener('pointermove', this.onPointerMove)
  }
}

export const modelIntersectionType = 'mp.modelIntersection'
export const makeModelIntersection = (): MpSdk.Scene.IComponent => {
  return new MModelIntersection()
}
