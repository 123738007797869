import _ from 'lodash'
import {
  SceneComponent,
  ComponentInteractionType
} from 'shared/components/SceneComponent'
import { MpSdk } from 'shared/bundle/sdk'
import { Object3D, Group, Vector3, MathUtils, Euler } from 'three'
import { SVGLoader, SVGResult } from 'three/examples/jsm/loaders/SVGLoader'
import { group } from 'console'

export interface IMMoveButtonInputs extends Record<string, unknown> {
  position: Vector3
  vector: Vector3
  visible: boolean
}

let iconData: SVGResult | undefined

const url =
  'https://firebasestorage.googleapis.com/v0/b/upstager-dev.appspot.com/o/upstager_gear.svg?alt=media&token=8a323a7c-27cc-43f2-a46e-343d1ffe9b13'
// const url =
// 'https://firebasestorage.googleapis.com/v0/b/upstager-dev.appspot.com/o/Button_move.svg?alt=media&token=d898bf81-102a-40e0-93ed-6ed78e6d314d'
// const iconUrl = 'https://firebasestorage.googleapis.com/v0/b/upstager-dev.appspot.com/o/upstager_move_cursor_512_2.svg?alt=media&token=2dcd12e7-5d82-4dd5-b0ca-b4fb226c928c'

class MMoveButton extends SceneComponent {
  private root: Object3D | null = null
  private icon: Group | null = null

  inputs: IMMoveButtonInputs = {
    position: new Vector3(),
    vector: new Vector3(),
    visible: false
  }

  events = {
    [ComponentInteractionType.CLICK]: true
  }

  // makeIcon (material: MeshBasicMaterial) {
  //   const THREE = this.context.three
  //   const geometry = new THREE.CircleGeometry(0.15, 32)
  //   this.icon = new THREE.Mesh(geometry, material)
  //   this.icon.rotateX(MathUtils.degToRad(-90))
  //   // console.log('circle created')
  //   // this.circle.rotateX(degToRad(90))
  //   if (this.root) {
  //     this.root?.add(this.icon)
  //     this.root?.position.copy(this.inputs.position)
  //     this.root.position.setY(this.inputs.position.y + 0.02)
  //   }
  // }

  loadSVG () {
    const THREE = this.context.three
    const ImgLoader = _.get(THREE, 'SVGLoader')
    const loader: SVGLoader = new ImgLoader()
    const r = this.root
    const group = new THREE.Group()
    this.icon = group

    const createObjectFromSVG = (data: SVGResult) => {
      const paths = data.paths
      group.scale.multiplyScalar(0.005)
      group.rotateX(MathUtils.degToRad(180))
      // group.rotateZ(MathUtils.degToRad(90))
      // group.rotateY(MathUtils.degToRad(90))
      group.position.x = -0.21
      // group.position.y = 70

      const guiData = {
        drawFillShapes: true,
        fillShapesWireframe: false,
        drawStrokes: true,
        strokesWireframe: true
      }

      for (let i = 0; i < paths.length; i++) {
        const path = paths[i]

        const fillColor = path.userData?.style.fill
        if (
          guiData.drawFillShapes &&
          fillColor !== undefined &&
          fillColor !== 'none'
        ) {
          const material = new THREE.MeshBasicMaterial({
            color: new THREE.Color().setStyle(fillColor).convertSRGBToLinear(),
            // opacity: path.userData?.style.fillOpacity,
            transparent: false,
            side: THREE.DoubleSide,
            depthWrite: true,
            depthTest: false,
            wireframe: guiData.fillShapesWireframe,
            toneMapped: true
          })

          const shapes = SVGLoader.createShapes(path)

          for (let j = 0; j < shapes.length; j++) {
            const shape = shapes[j]

            const geometry = new THREE.ShapeGeometry(shape)
            const mesh = new THREE.Mesh(geometry, material)
            group.add(mesh)
          }
        }

        const strokeColor = path.userData?.style.stroke

        if (
          guiData.drawStrokes &&
          strokeColor !== undefined &&
          strokeColor !== 'none'
        ) {
          const material = new THREE.MeshBasicMaterial({
            color: new THREE.Color()
              .setStyle(strokeColor)
              .convertSRGBToLinear(),
            // opacity: path.userData?.style.strokeOpacity,
            transparent: false,
            side: THREE.DoubleSide,
            depthWrite: true,
            depthTest: false,
            wireframe: guiData.strokesWireframe,
            toneMapped: true
          })

          for (let j = 0, jl = path.subPaths.length; j < jl; j++) {
            const subPath = path.subPaths[j]

            const geometry = SVGLoader.pointsToStroke(
              subPath.getPoints(),
              path.userData?.style
            )

            if (geometry) {
              const mesh = new THREE.Mesh(geometry, material)

              group.add(mesh)
            }
          }
        }
      }
      group.renderOrder = 3
      r?.add(group)
    }

    if (iconData) {
      createObjectFromSVG(iconData)
    } else {
      loader.load(url, function (data: SVGResult) {
        iconData = data
        createObjectFromSVG(data)
      })
    }

    const geometry = new THREE.SphereGeometry(40, 32, 16)
    const material = new THREE.MeshBasicMaterial({ color: 0xffff00 })
    material.transparent = true
    material.depthWrite = true
    material.depthTest = false
    material.opacity = 0.00001
    material.depthFunc = THREE.LessEqualDepth
    const sphere = new THREE.Mesh(geometry, material)
    sphere.position.x = 40
    sphere.position.y = 40
    group.add(sphere)
  }

  onInit () {
    const THREE = this.context.three
    this.root = new THREE.Object3D()
    this.root.visible = this.inputs.visible
    this.outputs.objectRoot = this.root
    this.outputs.collider = this.root

    this.loadSVG()
    this.root.position.copy(this.inputs.position)
    this.root.position.setY(this.inputs.position.y + 0.1)

    // new THREE.ImageLoader().setCrossOrigin('*').load(iconUrl, image => {
    //   console.log('image loaded', image)
    //   const texture = new THREE.CanvasTexture(image)
    //   texture.encoding = THREE.LinearEncoding
    //   const material = new THREE.MeshBasicMaterial({
    //     color: 0xffffff,
    //     opacity: 1,
    //     transparent: true,
    //     side: THREE.FrontSide,
    //     map: texture,
    //     // depthTest: false,
    //     // depthWrite: false,
    //     fog: false,
    //     toneMapped: false
    //   })
    //   this.makeIcon(material)
    // })
  }

  onInputsUpdated (oldInputs: IMMoveButtonInputs) {
    if (oldInputs.position !== this.inputs.position) {
      this.root?.position.copy(this.inputs.position)
      this.root?.position.setY(this.inputs.position.y + 0.1)
    }
    if (oldInputs.vector !== this.inputs.vector) {
      // console.log('this.inputs.vector', this.inputs.vector)
      let ang = this.inputs.vector.angleTo(new Vector3(0, 0, 1))
      if (this.inputs.vector.x < 0) {
        ang = -ang
      }
      // console.log('ang', ang)
      this.root?.rotation.set(0, ang, 0)
      const eulerUpdatedRotation = new Euler(0, ang, 0, 'XYZ')
      this.root?.quaternion.setFromEuler(eulerUpdatedRotation)
    }
    if (oldInputs.visible !== this.inputs.visible && this.root) {
      this.root.visible = this.inputs.visible
    }
  }

  onEvent (interactionType: ComponentInteractionType, eventData: unknown): void {
    // console.log('move button on Event', interactionType)
    if (interactionType === ComponentInteractionType.CLICK) {
      this.notify(ComponentInteractionType.CLICK, {
        type: interactionType,
        node: this.context.root,
        component: this
      })
    }
    // if (interactionType === ComponentInteractionType.HOVER) {
    //   this.notify(ComponentInteractionType.HOVER, {
    //     hover: (<{ hover: boolean }>eventData).hover
    //   })
    // }
    // if (interactionType === ComponentInteractionType.DRAG) {
    //   const event = eventData as DragEvent
    //   // console.log('drag event', event)
    //   this.notify(ComponentInteractionType.DRAG, event)
    //   // this.controlMap.onMouseMove(event.delta.x, event.delta.y);
    // }

    // if (interactionType === ComponentInteractionType.DRAG_END) {
    //   const event = eventData as DragEvent
    //   this.notify(ComponentInteractionType.DRAG_END, event)
    // }
  }

  onDestroy () {
    // console.log('move button on destroy')
    if (this.icon) {
      this.icon.clear()
      this.root?.remove(this.icon)
    }
  }
}

export const moveButtonType = 'mp.moveButton'
export const makeMoveButton = (): MpSdk.Scene.IComponent => {
  return new MMoveButton()
}
