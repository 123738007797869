import { useState, useMemo, useRef } from 'react'
import _ from 'lodash'
import {
  Box,
  Button,
  IconButton,
  Container,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  Text,
  Flex,
  FormErrorMessage,
  useBreakpointValue,
  InputGroup,
  InputRightElement,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react'
import { ReactComponent as Logo } from 'shared/assets/logo.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeLowVision } from '@fortawesome/pro-solid-svg-icons'
import { dbSignIn } from 'controllers/auth'

import validator from 'validator'
import { useNavigate } from 'react-router-dom'

const SignIn = () => {
  const navigate = useNavigate()
  const inputRef = useRef<HTMLInputElement>(null)
  const [email, setEmail] = useState('')
  const { isOpen, onToggle } = useDisclosure()
  const [password, setPassword] = useState('')
  const [emailError, setEmailError] = useState<string | null>(null)
  const [passwordError, setPasswordError] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)

  const onButtonPress = async () => {
    setLoading(true)
    const authError = await dbSignIn(email, password)
    setLoading(false)
    if (authError) {
      setPassword('')
      setPasswordError(authError)
    }
  }

  const onEmailBlur = () => {
    if (_.isEmpty(email)) {
      setEmailError('Email is required')
    } else if (!validator.isEmail(email)) {
      setEmailError('Please provide a properly formatted email address')
    } else {
      setEmailError(null)
    }
  }

  const onPasswordBlur = () => {
    if (_.isEmpty(password)) {
      setPasswordError('Password should not be empty')
    } else {
      setPasswordError(null)
    }
  }

  const onEmailFocus = () => setEmailError(null)
  const onPasswordFocus = () => setPasswordError(null)

  const buttonDisabled = useMemo(() => {
    return !validator.isEmail(email)
  }, [email])

  const onClickReveal = () => {
    onToggle()
    if (inputRef.current) {
      inputRef.current.focus({ preventScroll: true })
    }
  }

  const icon = useMemo(() => {
    if (isOpen) {
      return <FontAwesomeIcon icon={faEye} />
    } else {
      return <FontAwesomeIcon icon={faEyeLowVision} />
    }
  }, [isOpen])

  const goToSignUp = () => {
    navigate('/signup')
  }

  const renderPasswordInput = () => {
    return (
      <FormControl isInvalid={!_.isEmpty(passwordError)}>
        <FormLabel htmlFor='password'>Password</FormLabel>
        <InputGroup>
          <InputRightElement>
            <IconButton
              variant='link'
              aria-label={isOpen ? 'Mask password' : 'Reveal password'}
              icon={icon}
              onClick={onClickReveal}
            />
          </InputRightElement>
          <Input
            id='password'
            ref={inputRef}
            name='password'
            type={isOpen ? 'text' : 'password'}
            autoComplete='current-password'
            value={password}
            onChange={e => setPassword(e.target.value)}
            required
            onFocus={onPasswordFocus}
            onBlur={onPasswordBlur}
            isDisabled={loading}
          />
        </InputGroup>
        <FormErrorMessage>{passwordError}</FormErrorMessage>
      </FormControl>
    )
  }

  return (
    <Container
      maxW='lg'
      py={{ base: '12', md: '24' }}
      px={{ base: '0', sm: '8' }}
    >
      <Stack spacing='8'>
        <Stack spacing='6'>
          <Flex direction='row' justify={'center'} pb={8}>
            <Logo />
          </Flex>
          <Stack spacing={{ base: '2', md: '3' }} textAlign='center'>
            <Heading size={useBreakpointValue({ base: 'xs', md: 'lg' })}>
              Log in to your account
            </Heading>
            <HStack spacing='1' justify='center'>
              <Text color='muted'>{"Don't have an account?"}</Text>
              <Button variant='link' colorScheme='blue' onClick={goToSignUp}>
                Sign up
              </Button>
            </HStack>
          </Stack>
        </Stack>
        <Box
          py={{ base: '0', sm: '8' }}
          px={{ base: '4', sm: '10' }}
          bg={useBreakpointValue({ base: 'transparent', sm: 'bg-surface' })}
          boxShadow={{ base: 'none', sm: useColorModeValue('md', 'md-dark') }}
          borderRadius={{ base: 'none', sm: 'xl' }}
        >
          <Stack spacing='6'>
            <Stack spacing='5'>
              <FormControl isInvalid={!_.isEmpty(emailError)}>
                <FormLabel htmlFor='email'>Email</FormLabel>
                <Input
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  id='email'
                  type='email'
                  onBlur={onEmailBlur}
                  onFocus={onEmailFocus}
                  required
                  isDisabled={loading}
                />
                <FormErrorMessage>{emailError}</FormErrorMessage>
              </FormControl>
              {renderPasswordInput()}
            </Stack>
            {/* <HStack justify='space-between'>
              <Checkbox defaultIsChecked>Remember me</Checkbox>
              <Button variant='link' colorScheme='blue' size='sm'>
                Forgot password?
              </Button>
            </HStack> */}
            <Stack spacing='6'>
              <Button
                variant='solid'
                colorScheme={'teal'}
                isDisabled={buttonDisabled}
                onClick={onButtonPress}
                isLoading={loading}
              >
                Sign in
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Container>
  )
}

export default SignIn
