import _ from 'lodash'
import { useEffect, FC, useRef, useMemo } from 'react'

import { DictT } from 'shared/types/model'
import { MpSdk, Scene } from 'shared/bundle/sdk'
import { Euler, MathUtils, Vector3, Vector3Tuple, Box3, Object3D } from 'three'
import { TransformControls } from 'three/examples/jsm/controls/TransformControls'
import { ComponentInteractionType } from 'shared/components/SceneComponent'

type ItemProps = {
  id: string
  sdk: MpSdk
  url: string
  position?: Vector3
  offset?: Vector3
  rotation?: Vector3Tuple
  scale?: number
  canMove?: boolean
  slotSize?: Vector3Tuple
  onMove?: (toPosition: Vector3) => void
  onClick?: () => void
  sceneObject: MpSdk.Scene.IObject
  boundingBox?: Vector3
  setBoundingBox?: (boundingBox: Vector3) => void
  showBox?: boolean
  showLoadingIndicator?: boolean
  isSelected?: boolean
  viewMode?: string
  onHover?: () => void
  onBlur?: () => void
}

const Item: FC<ItemProps> = ({
  id,
  url,
  position = new Vector3(0, 0, 0),
  offset = new Vector3(0, 0, 0),
  rotation = [0, 0, 0],
  scale = 1,
  canMove = false,
  slotSize = [1, 1, 1],
  onMove = () => null,
  onClick,
  sceneObject,
  boundingBox,
  setBoundingBox,
  showBox = true,
  showLoadingIndicator = true,
  isSelected = false,
  viewMode = '',
  onHover,
  onBlur
}) => {
  const nodesRef = useRef<DictT<MpSdk.Scene.INode>>({})
  const spiesRef = useRef<DictT<MpSdk.ISubscription>>({})

  useEffect(() => {
    if (nodesRef.current.itemNode) {
      nodesRef.current.itemNode.scale.set(scale, scale, scale)
      checkBoundingBox(nodesRef.current.itemNode)
    }
  }, [scale])

  const itemPosition = useMemo(() => {
    return position.clone().add(offset)
  }, [position, offset])

  useEffect(() => {
    if (nodesRef.current.itemNode) {
      // console.log('update item node position', position)
      nodesRef.current.itemNode.position.copy(itemPosition)
      checkBoundingBox(nodesRef.current.itemNode)
    }
    if (nodesRef.current.loadingLogo) {
      nodesRef.current.loadingLogo.position.copy(itemPosition)
    }
  }, [position, offset])

  useEffect(() => {
    if (nodesRef.current.itemNode) {
      // console.log('update item node rotation', rotation)
      const eulerUpdatedRotation = new Euler(
        MathUtils.degToRad(rotation[0]),
        MathUtils.degToRad(rotation[1]),
        MathUtils.degToRad(rotation[2]),
        'XYZ'
      )
      nodesRef.current.itemNode.quaternion.setFromEuler(eulerUpdatedRotation)
      checkBoundingBox(nodesRef.current.itemNode)
    }
  }, [rotation])

  // useEffect(() => {
  //   if (nodesRef.current.transformNode) {
  //     nodesRef.current.transformNode.stop()
  //   }

  //   // const itemNode = nodesRef.current.itemNode
  //   // addTransformControl(itemNode)
  // }, [showBox])

  useEffect(() => {
    console.log('useEffect viewMode', viewMode, isSelected)
    const transformNode = nodesRef.current.transformNode
    console.log(
      'useEffect viewMode',
      viewMode,
      'isSelected',
      isSelected,
      'transformNode',
      transformNode
    )
    const itemNode = nodesRef.current.itemNode
    if (viewMode !== 'mode.floorplan') {
      if (isSelected && !transformNode) {
        addTransformControl(itemNode)
      } else if (!isSelected && transformNode) {
        console.log('%cstop transform controls, not isSelected', 'color: red;')
        transformNode.stop()
        delete nodesRef.current.transformNode
      }
    } else if (transformNode) {
      console.log('%cstop transform controls, mode.floorplan', 'color: red;')
      transformNode.stop()
      delete nodesRef.current.transformNode
    } else {
      console.log('no transform node')
    }
  }, [viewMode, isSelected])

  const addOnClickListener = (
    node: Scene.INode,
    component: Scene.IComponent
  ) => {
    if (onClick) {
      const evPath = sceneObject.addPath({
        id: `item_click_${id}`,
        type: 'emit' as Scene.PathType.EMIT,
        node,
        component: component,
        property: ComponentInteractionType.CLICK
      })
      const spy = {
        path: evPath,
        onEvent: () => {
          onClick()
        }
      }
      sceneObject.spyOnEvent(spy)
    }
  }

  const addOnHoverListener = (
    node: Scene.INode,
    component: Scene.IComponent
  ) => {
    // if (onClick) {
    const evPath = sceneObject.addPath({
      id: `item_hover_${id}`,
      type: 'emit' as Scene.PathType.EMIT,
      node,
      component: component,
      property: ComponentInteractionType.HOVER
    })
    const spy = {
      path: evPath,
      onEvent: (e: { hover: boolean }) => {
        if (e.hover) {
          onHover && onHover()
        } else {
          onBlur && onBlur()
        }
      }
    }
    sceneObject.spyOnEvent(spy)
  }

  const addTransformControl = (node: MpSdk.Scene.INode) => {
    if (isSelected && viewMode !== 'mode.floorplan') {
      console.log('%cadd transform controls', 'color: green;')
      const transformNode = sceneObject.addNode()
      nodesRef.current.transformNode = transformNode
      const transformComponent = transformNode.addComponent(
        'mp.transformControls',
        {
          selection: node
        }
      )

      // console.log('transformComponent', transformComponent)
      transformNode.start()

      const tc: TransformControls = transformComponent.outputs
        .objectRoot as TransformControls
      tc.addEventListener('mouseUp', () => onMove(node.position))
      tc.addEventListener('mouseMove', () => console.log('TC: mouse move'))
      tc.addEventListener('mouseDown', () => console.log('TC: mouse down'))
    }
  }

  const initLoadingIndicator = (
    itemComponent: MpSdk.Scene.IComponent,
    itemNode: MpSdk.Scene.INode
  ) => {
    const loadingLogo = sceneObject.addNode('loading_logo')
    loadingLogo.position.copy(itemPosition)
    const logoComponent = loadingLogo.addComponent('mp.objLoader', {
      url: 'https://firebasestorage.googleapis.com/v0/b/upstager-dev.appspot.com/o/brand%20(1).obj?alt=media&token=09a00dbb-8792-4375-9d48-e1d24c10b831',
      localScale: { x: 0.01, y: 0.01, z: 0.01 },
      localRotation: { x: 0, y: 0, z: 0 },
      localPosition: { x: 0, y: 0.2, z: 0 }
    })

    const loadingIndicatorNode = sceneObject.addNode('loading-indicator')
    const loadingComponent = loadingIndicatorNode.addComponent(
      'mp.loadingIndicator',
      {
        size: new Vector3(slotSize[0], slotSize[1], slotSize[2])
      }
    )

    const logoOutputPath = sceneObject.addPath({
      id: 'logo_loading_status_listener',
      type: 'output' as Scene.PathType.OUTPUT,
      node: loadingLogo,
      component: logoComponent,
      property: 'objectRoot'
    })
    const logoInputPath = sceneObject.addPath({
      id: 'logo_loading_status_listener_input',
      type: 'input' as Scene.PathType.INPUT,
      node: loadingIndicatorNode,
      component: loadingComponent,
      property: 'logo'
    })
    sceneObject.bindPath(logoInputPath, logoOutputPath)

    const laOutputPath = sceneObject.addPath({
      id: 'glb_loading_status_listener',
      type: 'output' as Scene.PathType.OUTPUT,
      node: itemNode,
      component: itemComponent,
      property: 'loadingState'
    })
    const laInputPath = sceneObject.addPath({
      id: 'glb_loading_status_listener_input',
      type: 'input' as Scene.PathType.INPUT,
      node: loadingIndicatorNode,
      component: loadingComponent,
      property: 'loadingState'
    })
    sceneObject.bindPath(laInputPath, laOutputPath)

    const outputSpy = {
      path: laOutputPath,
      onEvent (type: string) {
        if (type === 'Loaded') {
          checkBoundingBox(itemNode)
        }
      }
    }
    const spyLoading = sceneObject.spyOnEvent(outputSpy)
    spiesRef.current.spyLoading = spyLoading
    nodesRef.current.loadingIndicatorNode = loadingIndicatorNode
    nodesRef.current.loadingLogo = loadingLogo
    loadingLogo.start()
    loadingIndicatorNode.start()
  }

  const checkBoundingBox = (node: MpSdk.Scene.INode) => {
    if (setBoundingBox) {
      const obj = _.get(node, 'obj3D')
      const bbox = new Box3().setFromObject(obj)
      // console.log('checkBoundingBox: bbox', bbox)
      if (obj && _.isFinite(bbox.max.x)) {
        const newBbox = new Vector3(
          bbox.max.x - bbox.min.x,
          bbox.max.y - bbox.min.y,
          bbox.max.z - bbox.min.z
        )
        if (!_.isEqual(boundingBox, newBbox)) {
          setBoundingBox(newBbox)
        }
      }
    }
  }

  const recSetShadows = (obj: Object3D) => {
    // console.log('recSetShadows obj', obj)
    obj.castShadow = true
    obj.receiveShadow = true
    obj.userData.isFurniture = true
    obj.userData.itemId = id
    obj.layers.set(8)
    obj.layers.enable(8)
    if (!_.isEmpty(obj.children)) {
      _.forEach(obj.children, recSetShadows)
    }
  }

  useEffect(() => {
    const run = async () => {
      try {
        // console.log(
        //   'init item, position',
        //   position,
        //   'offset',
        //   offset,
        //   'itemPosition',
        //   itemPosition
        // )
        if (!_.isEmpty(nodesRef.current)) {
          _.forEach(nodesRef.current, n => n.stop())
        }
        const node = sceneObject.addNode('item_preview')
        const onLoaded = (v: Object3D | null) => {
          if (v) {
            v.castShadow = true
            v.receiveShadow = true
            recSetShadows(v)
            v.userData.isFurnitureMain = true
            v.userData.isFurniture = true
            v.userData.itemId = id
            // v.renderOrder = 2
            v.layers.set(8)
            v.layers.enable(8)
            // console.log('v', v)
            // _.forEach(v.children, (objCh: any) => {
            //   console.log('objCH', objCh)
            //   objCh.castShadow = true
            //   objCh.receiveShadow = true
            // })
            addTransformControl(node)
          }
          // console.log('onLoaded', v)
        }
        const itemComponent = node.addComponent('mp.gltfLoader', {
          url,
          onLoaded
        })
        nodesRef.current.itemNode = node
        // console.log('itemComponent', itemComponent)

        node.position.copy(itemPosition)
        if (rotation) {
          const eulerUpdatedRotation = new Euler(
            MathUtils.degToRad(rotation[0]),
            MathUtils.degToRad(rotation[1]),
            MathUtils.degToRad(rotation[2]),
            'XYZ'
          )
          node.quaternion.setFromEuler(eulerUpdatedRotation)
        }
        node.name = id
        node.scale.set(scale, scale, scale)
        if (showLoadingIndicator) {
          initLoadingIndicator(itemComponent, node)
        }

        itemComponent.emits = {
          [ComponentInteractionType.CLICK]: true,
          [ComponentInteractionType.HOVER]: true
        }
        addOnClickListener(node, itemComponent)
        addOnHoverListener(node, itemComponent)
        // addTransformControl(node)
        node.start()
      } catch (e) {
        console.warn('item creation error, itemId', id, e)
      }
    }

    run()

    return () => {
      _.forEach(nodesRef.current, n => n.stop())
      _.forEach(spiesRef.current, n => n.cancel())
    }
  }, [])

  return null
}

export default Item
