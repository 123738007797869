import { FC, useMemo } from 'react'
import _ from 'lodash'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { useSelector } from 'model/hooks'
import { getAppLoaded } from 'model/selectors/base'
import NavWrapper from 'navigation/NavWrapper'
import Designs from 'pages/Designs'
import EditTour from 'pages/EditTour'
import SignIn from 'pages/auth/SignIn'
import SignUp from 'pages/auth/SignUp'
import Loading from 'pages/Loading'
import Settings from 'pages/Settings'
import Orders from 'pages/Orders'
import Order from 'pages/Order'
import Tours from 'pages/Tours'
import DashboardWrapper from 'pages/dashboard/DashboardWrapper'
import Suppliers from 'pages/Suppliers'

const AppRouter: FC<{}> = () => {
  const authData = useSelector(state => state.authData)
  const appLoaded = useSelector(getAppLoaded)

  const publicRoutes = (
    <>
      <Route path='/signin' element={<SignIn />} />
      <Route path='/signup' element={<SignUp />} />
    </>
  )

  const privateRoutes = useMemo(() => {
    if (_.isNil(authData) || !_.isNil(appLoaded)) {
      return <Route path='*' element={<Loading />} />
    } else {
      return (
        <>
          <Route path='/' element={<DashboardWrapper />}>
            <Route path='/designs' element={<Designs />} />
            <Route path='/orders/:orderId' element={<Order />} />
            <Route path='/orders' element={<Orders />} />
            <Route path='/settings' element={<Settings />} />
            <Route path='/suppliers' element={<Suppliers />} />
            <Route path='/tours' element={<Tours />} />
            <Route path='/' element={<Navigate replace to='/designs' />} />
            <Route path='*' element={<Navigate replace to='/designs' />} />
          </Route>
          <Route path='/tour/:tourId' element={<EditTour />} />
        </>
      )
    }
  }, [authData, appLoaded])

  return (
    <BrowserRouter basename='/'>
      <NavWrapper>
        <Routes>
          {privateRoutes}
          {publicRoutes}
        </Routes>
      </NavWrapper>
    </BrowserRouter>
  )
}

export default AppRouter
