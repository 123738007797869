import {
  ComponentOutput,
  SceneComponent
} from 'shared/components/SceneComponent'
import { MpSdk } from '../../bundle/sdk'
import { Object3D, Raycaster, Vector2, Event, Intersection } from 'three'
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import _ from 'lodash'

interface Outputs extends ComponentOutput {
  selectedObjects: string[]
}

export interface IMOutlinePassInputs extends Record<string, unknown> {
  effectComposer: EffectComposer | null
  excludeItem: string | null
  position: Vector2
}

class MOutlinePass extends SceneComponent {
  private root: Object3D | null = null
  private outlinePass: OutlinePass | null = null
  private composer: EffectComposer | null = null
  private selectedObjects: Object3D[] = []
  private raycaster: Raycaster | null = null
  private prevIntersects: Intersection<Object3D<Event>>[] | undefined
  // private circle: Mesh | null = null

  inputs: IMOutlinePassInputs = {
    effectComposer: null,
    excludeItem: null,
    position: new Vector2(0, 0)
  }

  outputs = {
    selectedObjects: []
  } as Outputs

  private getFurnitureMain = (obj: Object3D<Event>): Object3D<Event> | null => {
    const isFurnitureMain = _.get(obj, 'userData.isFurnitureMain', false)
    if (isFurnitureMain) {
      if (this.inputs.excludeItem && this.inputs.excludeItem === obj.name) {
        return null
      } else {
        // console.log('furniture main', obj.name, obj.userData)
        return obj
      }
    } else if (obj && obj.parent) {
      return this.getFurnitureMain(obj.parent)
    } else {
      return null
    }
  }

  onInputsUpdated (oldInputs: IMOutlinePassInputs) {
    if (oldInputs.excludeItem !== this.inputs.excludeItem) {
      this.prevIntersects = undefined
      this.selectedObjects = []
      if (this.outlinePass) {
        this.outlinePass.selectedObjects = this.selectedObjects
      }
    }
    if (oldInputs.position !== this.inputs.position) {
      // console.log('position changed', this.inputs.position)
      this.onPointerMove(this.inputs.position)
    }
  }

  onPointerMove = (mouse: Vector2) => {
    // if (event.isPrimary === false) return
    if (_.isNil(this.outlinePass)) return
    // const mouse = new Vector2()
    // mouse.x = (event.clientX / window.innerWidth) * 2 - 1
    // mouse.y = -(event.clientY / window.innerHeight) * 2 + 1
    this.raycaster?.setFromCamera(mouse, this.context.camera)

    const intersects = this.raycaster?.intersectObject(this.context.scene, true)
    if (!_.isEqual(intersects, this.prevIntersects)) {
      this.prevIntersects = intersects
      // console.log('intersects', intersects)
      // const meshesObjects = []
      this.selectedObjects = []

      const objEv: any = _.find(intersects, i =>
        _.get(i.object, 'userData.isFurniture', false)
      )
      if (objEv) {
        const obj = objEv.object
        const furnitureObject: Object3D<Event> | null =
          this.getFurnitureMain(obj)
        if (furnitureObject) {
          // console.log('furnitureObject', furnitureObject)
          this.selectedObjects.push(furnitureObject)
          // this.selectedObjects = furnitureObject.children
        }
      }
      if (this.outlinePass) {
        this.outlinePass.selectedObjects = this.selectedObjects
        this.outputs.selectedObjects = _.compact(
          _.map(this.selectedObjects, o => _.get(o, 'userData.itemId'))
        )
      }
    } else {
      // console.log(
      //   '----- same intersects ----- skip calculations',
      //   this.selectedObjects
      // )
    }
    // _.forEach(intersects, intersect => {
    //   this.selectedObjects.push(intersect.object)
    //   if (this.outlinePass) {
    //     this.outlinePass.selectedObjects = this.selectedObjects
    //   }
    // })
    // if (intersects && intersects.length > 0 && this.outlinePass) {
    //   const selectedObject = intersects[0].object
    //   this.outlinePass.selectedObjects = [selectedObject]
    //   // this.outlinePass.selectedObjects = _.map(intersects, i => i.object)
    // }
  }

  onInit () {
    console.log('%coutline pass onInit', 'color: red;', this.context)
    const THREE = this.context.three
    console.log('THREE', THREE)
    // const renderer = this.context.renderer
    this.root = new THREE.Object3D()
    this.outputs.objectRoot = this.root
    this.outputs.collider = this.root
    this.composer = this.inputs?.effectComposer
    if (this.composer) {
      const OPass = _.get(this.context.three, 'OutlinePass')
      console.log('OutlinePassClass', OPass)
      // console.log('this.root.parent', this.root.parent)
      const oPass: OutlinePass = new OPass(
        new THREE.Vector2(window.innerWidth, window.innerHeight),
        this.context.scene,
        this.context.camera
      )
      this.outlinePass = oPass
      oPass.edgeStrength = 3
      oPass.edgeGlow = 1
      oPass.resolution.set(2048, 2048)
      oPass.visibleEdgeColor.set(new THREE.Color('#FFD700'))
      oPass.hiddenEdgeColor.set(new THREE.Color('#FFD700'))
      this.composer.insertPass(oPass, 1)
      this.raycaster = new this.context.three.Raycaster()
      this.raycaster.layers.set(8)
      // this.raycaster.layers.enableAll()
      this.raycaster.params = {
        Mesh: {},
        Line: { threshold: 1 },
        LOD: {},
        Points: { threshold: 1 },
        Sprite: {}
      }
      // const showcaseElt = window.document.getElementById('showcase')
      // console.log('showcaseElt', showcaseElt)
      // showcaseElt?.addEventListener('pointermove', this.onPointerMove)
      // renderer.domElement.addEventListener('pointermove', this.onPointerMove)
    } else {
      console.error('effect composer is null')
    }
    console.log('this.context', this.context)
  }

  onDestroy () {
    console.log('%coutline pass onDestroy', 'color: red;')
    if (this.outlinePass) {
      this.composer?.removePass(this.outlinePass)
    }
  }
}

export const outlinePassType = 'mp.outlinePass'
export const makeOutlinePass = (): MpSdk.Scene.IComponent => {
  return new MOutlinePass()
}
