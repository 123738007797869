import { DictT, TourT } from 'shared/types/model'
import * as types from 'model/actionTypes'

const initialState: DictT<TourT> = {}

export default function (
  state = initialState,
  action: { type: string; tours: DictT<TourT> }
) {
  switch (action.type) {
    case types.RECEIVE_TOURS:
      return action.tours
    case types.CLEAR:
    case types.LOGOUT:
      return initialState
    default:
      return state
  }
}
