import * as types from 'model/actionTypes'
import { SlotT, DictT } from 'shared/types/model'

const initialState: DictT<SlotT> = {}

export default function (
  state = initialState,
  action: { type: string; slots: DictT<SlotT> }
) {
  switch (action.type) {
    case types.RECEIVE_SLOTS:
      return {
        ...state,
        ...action.slots
      }
    case types.CLEAR:
    case types.LOGOUT:
      return initialState
    default:
      return state
  }
}
