import { FC } from 'react'
import { VStack, Flex } from '@chakra-ui/react'
import NavBar from 'components/NavBar'
import ToursContent from 'pages/tours/ToursContent'

const Tours: FC = () => {
  return (
    <VStack h='full' w='full'>
      <NavBar title='Tours' />
      <Flex
        direction={'column'}
        w='full'
        justify={'flex-start'}
        overflow={'hidden'}
        flex={1}
      >
        <ToursContent />
      </Flex>
    </VStack>
  )
}

export default Tours
