import * as types from 'model/actionTypes'
import { SupplierT, DictT } from 'shared/types/model'
import { AnyAction } from 'redux'

const initialState: DictT<SupplierT> = {}

export default function (
  state = initialState,
  action: { type: string; suppliers: DictT<SupplierT> } | AnyAction
) {
  switch (action.type) {
    case types.RECEIVE_SUPPLIERS:
      return action.suppliers
    case types.CLEAR:
    case types.LOGOUT:
      return initialState
    default:
      return state
  }
}
