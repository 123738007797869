import { FC, MouseEvent } from 'react'
import {
  Flex,
  HStack,
  Image,
  Text,
  VStack,
  Checkbox,
  Badge
} from '@chakra-ui/react'
import numeral from 'numeral'
import _ from 'lodash'

type Props = {
  name: string
  description: string
  quantity: number
  price: number
  imageUrl: string
  supplier: string
  checked?: boolean
  toggleItem?: (v: boolean) => void
  canSelect?: boolean
}

const OrderItem: FC<Props> = ({
  name,
  description,
  quantity,
  imageUrl,
  price,
  supplier,
  checked,
  toggleItem,
  canSelect
}) => {
  // const onCheckBoxClick = (event: ChangeEvent<HTMLInputElement>) => {
  //   // event.stopPropagation()
  //   // event.preventDefault()
  //   console.log('onCheckbox click')
  //   // toggleItem(event.target.checked)
  // }

  const onRowClick = (e: MouseEvent<HTMLDivElement>) => {
    console.log('onRowClick')
    e.preventDefault()
    toggleItem && toggleItem(!checked)
  }
  return (
    <Flex
      justify={'space-between'}
      w='full'
      align='center'
      opacity={!canSelect || checked ? 1 : 0.4}
      _hover={canSelect ? { bg: 'gray.200', opacity: 0.8 } : {}}
      py={2}
      px={4}
      as={canSelect ? 'button' : undefined}
      onClick={canSelect ? onRowClick : undefined}
    >
      <HStack align={'center'} pr={4}>
        {canSelect && <Checkbox opacity={1} isChecked={checked} />}
        <Image
          rounded='md'
          minWidth='24'
          maxWidth='24'
          height={{ base: '20', md: '24' }}
          fit='contain'
          src={imageUrl}
          alt={name}
          draggable='false'
          loading='lazy'
        />
        <VStack spacing={0} align='flex-start'>
          <Text fontWeight='medium'>{name}</Text>
          <Text color='gray.500' noOfLines={2} fontSize='xs'>
            {description}
          </Text>
          <Badge colorScheme='green'>
            {_.truncate(supplier, { length: 16 })}
          </Badge>
        </VStack>
      </HStack>
      <VStack align={'center'} spacing={1} flexShrink={0}>
        <Text fontSize={'sm'} color='gray.600'>
          {quantity} x {numeral(price).format('$0,0.00')}
        </Text>
        <Text fontSize={'md'} color='gray.900' fontWeight={'semibold'}>
          {numeral(price * quantity).format('$0,0.00')}
        </Text>
      </VStack>
    </Flex>
  )
}

export default OrderItem
