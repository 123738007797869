import { collection, query, getDocs } from 'firebase/firestore'
import _ from 'lodash'

import { db } from 'controllers/db'
import store from 'model/store'
import { TourModelT } from 'shared/types/model'
import { receiveTourModels } from 'model/actions/tourModelsAC'

export const fetchTourModels = async () => {
  try {
    const q = query(collection(db, 'tourModels'))
    const modelsSN = await getDocs(q)
    const tourModelsAr = _.map(modelsSN.docs, doc => {
      return doc.data() as TourModelT
    })
    const tourModels = _.keyBy(tourModelsAr, 'id')
    store.dispatch(receiveTourModels(tourModels))
  } catch (e) {
    console.log('fetchProjects error', e)
  }
}
