import { useState, useMemo, useRef } from 'react'
import _ from 'lodash'
import {
  Box,
  Button,
  IconButton,
  Container,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  Text,
  Flex,
  FormErrorMessage,
  useBreakpointValue,
  InputGroup,
  InputRightElement,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react'
import { ReactComponent as Logo } from 'shared/assets/logo.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeLowVision } from '@fortawesome/pro-solid-svg-icons'
import { dbSignUp } from 'controllers/auth'

import validator from 'validator'
import { useNavigate } from 'react-router-dom'
import { DictT } from 'types/model'

const PSWD_LENGTH = 6

const SignUp = () => {
  const navigate = useNavigate()
  const inputRef = useRef<HTMLInputElement>(null)
  const [email, setEmail] = useState('')
  const { isOpen, onToggle } = useDisclosure()
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState<DictT<string | null>>({})
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')

  const onButtonPress = async () => {
    setLoading(true)
    const authError = await dbSignUp(name, email, password)
    setLoading(false)
    if (authError) {
      setPassword('')
      setErrors({ ...errors, password: authError })
    }
  }

  const onEmailBlur = () => {
    if (_.isEmpty(email)) {
      setErrors({ ...errors, email: 'Email is required' })
    } else if (!validator.isEmail(email)) {
      setErrors({
        ...errors,
        email: 'Please provide a properly formatted email address'
      })
    } else {
      setErrors({ ...errors, email: null })
    }
  }

  const onPasswordBlur = () => {
    if (_.isEmpty(password)) {
      setErrors({ ...errors, password: 'Password should not be empty' })
    } else if (_.size(password) < 6) {
      setErrors({
        ...errors,
        password: `Enter at least ${PSWD_LENGTH} characters please`
      })
    } else {
      setErrors({ ...errors, password: null })
    }
  }

  const onNameBlur = () => {
    if (_.isEmpty(name)) {
      setErrors({ ...errors, name: 'Please enter your name' })
    } else {
      setErrors({ ...errors, name: null })
    }
  }

  const onEmailFocus = () => setErrors({ ...errors, email: null })
  const onPasswordFocus = () => setErrors({ ...errors, password: null })

  const buttonDisabled = useMemo(() => {
    return (
      !validator.isEmail(email) ||
      _.isEmpty(password) ||
      _.size(password) < PSWD_LENGTH ||
      _.isEmpty(name)
    )
  }, [email, password, name])

  const onClickReveal = () => {
    onToggle()
    if (inputRef.current) {
      inputRef.current.focus({ preventScroll: true })
    }
  }

  const icon = useMemo(() => {
    if (isOpen) {
      return <FontAwesomeIcon icon={faEye} />
    } else {
      return <FontAwesomeIcon icon={faEyeLowVision} />
    }
  }, [isOpen])

  const goToSignIn = () => {
    navigate('/signin')
  }

  const renderPasswordInput = () => {
    return (
      <FormControl isInvalid={!_.isEmpty(errors.password)}>
        <FormLabel htmlFor='password'>Password</FormLabel>
        <InputGroup>
          <InputRightElement zIndex={2}>
            <IconButton
              variant='link'
              aria-label={isOpen ? 'Mask password' : 'Reveal password'}
              icon={icon}
              onClick={onClickReveal}
            />
          </InputRightElement>
          <Input
            id='password'
            ref={inputRef}
            name='password'
            type={isOpen ? 'text' : 'password'}
            autoComplete='current-password'
            value={password}
            onChange={e => setPassword(e.target.value)}
            required
            onFocus={onPasswordFocus}
            onBlur={onPasswordBlur}
            isDisabled={loading}
          />
        </InputGroup>
        <FormErrorMessage>{errors.password}</FormErrorMessage>
      </FormControl>
    )
  }

  const renderNameField = () => {
    return (
      <FormControl isInvalid={!_.isEmpty(errors.name)}>
        <FormLabel htmlFor='name'>Name</FormLabel>
        <Input
          value={name}
          onChange={e => setName(e.target.value)}
          id='name'
          type='name'
          onBlur={onNameBlur}
          onFocus={() => setErrors({ ...errors, name: null })}
          required
          isDisabled={loading}
        />
        <FormErrorMessage>{errors.name}</FormErrorMessage>
      </FormControl>
    )
  }

  return (
    <Container
      maxW='lg'
      py={{ base: '12', md: '24' }}
      px={{ base: '0', sm: '8' }}
    >
      <Stack spacing='8'>
        <Stack spacing='6'>
          <Flex direction='row' justify={'center'} pb={8}>
            <Logo />
          </Flex>
          <Stack spacing={{ base: '2', md: '3' }} textAlign='center'>
            <Heading size={useBreakpointValue({ base: 'xs', md: 'lg' })}>
              Enter your info to get started
            </Heading>
            <HStack spacing='1' justify='center'>
              <Text color='muted'>Already have an account?</Text>
              <Button variant='link' colorScheme='blue' onClick={goToSignIn}>
                Sign in
              </Button>
            </HStack>
          </Stack>
        </Stack>
        <Box
          py={{ base: '0', sm: '8' }}
          px={{ base: '4', sm: '10' }}
          bg={useBreakpointValue({ base: 'transparent', sm: 'bg-surface' })}
          boxShadow={{ base: 'none', sm: useColorModeValue('md', 'md-dark') }}
          borderRadius={{ base: 'none', sm: 'xl' }}
        >
          <Stack spacing='6'>
            <Stack spacing='5'>
              {renderNameField()}
              <FormControl isInvalid={!_.isEmpty(errors.email)}>
                <FormLabel htmlFor='email'>Email</FormLabel>
                <Input
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  id='email'
                  type='email'
                  onBlur={onEmailBlur}
                  onFocus={onEmailFocus}
                  required
                  isDisabled={loading}
                />
                <FormErrorMessage>{errors.email}</FormErrorMessage>
              </FormControl>
              {renderPasswordInput()}
            </Stack>
            <Stack spacing='6'>
              <Button
                variant='solid'
                colorScheme={'teal'}
                isDisabled={buttonDisabled}
                onClick={onButtonPress}
                isLoading={loading}
              >
                Create my account
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Container>
  )
}

export default SignUp
