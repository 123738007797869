import { FC } from 'react'
import { ItemT } from 'shared/types/model'
import _ from 'lodash'
import {
  HStack,
  Text,
  Image,
  VStack,
  Button,
  Flex,
  Badge
} from '@chakra-ui/react'
import { getItemPhotoUrl } from 'shared/utils/itemsUtils'
import numeral from 'numeral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faTrashCan } from '@fortawesome/pro-regular-svg-icons'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { MpSdk } from 'shared/bundle/sdk'

type Props = {
  sdk: MpSdk
  item: ItemT
  index: number
  quantity: number
  openProductCard: (itemId: string) => void
  addToRoom: (itemId: string) => void
  onRemove: () => void
  mattertagId?: string
  supplierName?: string
}
const PanelCartItem: FC<Props> = ({
  sdk,
  item,
  index,
  quantity,
  openProductCard,
  onRemove,
  mattertagId,
  supplierName
}) => {
  const navigateToItem = async () => {
    if (mattertagId) {
      sdk.Mattertag.navigateToTag(mattertagId, sdk.Mattertag.Transition.FLY)
    }
  }

  return (
    <VStack w='full' pb={4} _hover={{ bg: 'gray.200' }} px={4} role='group'>
      <HStack
        pt={4}
        bg='transparent'
        align={'flex-start'}
        w='full'
        spacing={4}
        scrollSnapAlign='start'
      >
        <Flex minW={'100px'} position='relative'>
          <Image boxSize='100' src={getItemPhotoUrl(item)} rounded={'lg'} />
          <Flex
            bg='whiteAlpha.700'
            position={'absolute'}
            w='100px'
            h='100px'
            left={0}
            top={0}
            rounded={'lg'}
            direction='column'
            justify={'center'}
            align='center'
            color='gray.600'
            as='button'
            onClick={navigateToItem}
            display='none'
            pt={4}
            _groupHover={{ display: 'flex' }}
          >
            <FontAwesomeIcon icon={faEye} size='2x' />
            <Text pt={1} fontSize='xs' color='gray.700' fontWeight={'semibold'}>
              View in room
            </Text>
          </Flex>
        </Flex>
        <VStack align={'flex-start'} w='full'>
          <Text fontWeight={'semibold'} lineHeight={1.2} color='blue.800'>
            {item.title}{' '}
            {quantity > 1 && (
              <Text as='span' fontWeight={'normal'} color='gray.500'>
                ({index + 1} of {quantity})
              </Text>
            )}
          </Text>
          <Text lineHeight={1.2} color='gray.500' fontSize={'xs'} noOfLines={5}>
            {item.desc}
          </Text>
          <Flex w='full' justify={'space-between'}>
            <Button
              size='xs'
              variant={'link'}
              colorScheme='gray.600'
              iconSpacing={1}
              rightIcon={
                <FontAwesomeIcon
                  icon={faChevronRight}
                  color='gray.600'
                  fontSize={'0.8em'}
                />
              }
              onClick={() => openProductCard(item.id)}
            >
              More info
            </Button>
            <Badge colorScheme='green'>
              {_.truncate(supplierName, { length: 16 })}
            </Badge>
          </Flex>
        </VStack>
      </HStack>
      <Flex justify={'space-between'} w='full'>
        <Flex minW={'100px'} justify='center'>
          <Button
            leftIcon={<FontAwesomeIcon icon={faTrashCan} />}
            variant='link'
            size={'xs'}
            colorScheme='red'
            display='none'
            _groupHover={{ display: 'block' }}
            onClick={onRemove}
          >
            Remove
          </Button>
        </Flex>

        <Text fontSize={'lg'} fontWeight='bold' color='blue.800'>
          {numeral(item.price).format('$0,0.00')}
        </Text>
      </Flex>
    </VStack>
  )
}

export default PanelCartItem
