import {
  useImperativeHandle,
  ForwardRefRenderFunction,
  Ref,
  forwardRef,
  useState,
  useMemo
} from 'react'
import _ from 'lodash'
import {
  Button,
  Flex,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
  FormControl,
  FormLabel,
  VStack
} from '@chakra-ui/react'
import { Select, OptionBase, GroupBase, SingleValue } from 'chakra-react-select'

import { getAddress } from 'shared/utils/addressUtils'
import { generateId } from 'controllers/db'
import { TourT, TourModelT } from 'shared/types/model'
import { createTour } from 'controllers/tours'
import { useSelector } from 'model/hooks'
import { useNavigate } from 'react-router-dom'

interface OptionT extends OptionBase {
  label: string
  value: string
}
export interface ICreateTourModal {
  open: (tourModelId?: string) => void
}

type Props = {}

const CreateTourModal: ForwardRefRenderFunction<ICreateTourModal, Props> = (
  _props: Props,
  ref: Ref<unknown>
) => {
  const navigate = useNavigate()
  const user = useSelector(state => state.user)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [name, setName] = useState('')
  const [tourModelId, setTourModelId] = useState<string | null>(null)
  const [modelSelectorHidden, setModelSelectorHidden] = useState(false)
  const tourModels = useSelector(state => state.tourModels)

  useImperativeHandle(ref, () => ({
    open: (tmId?: string) => {
      if (tmId) {
        setTourModelId(tmId)
      }
      setModelSelectorHidden(!_.isNil(tmId))
      onOpen()
    }
  }))

  const canCreate = () => {
    return !_.isEmpty(name) && !_.isEmpty(tourModelId)
  }

  const onCreateClick = async () => {
    const id = generateId()
    const userId = user?.id
    if (tourModelId && name && userId) {
      const tour: TourT = {
        id,
        createdAt: _.now(),
        name,
        tourModelId,
        slots: {},
        partnerId: userId
      }
      await createTour(tour)
      setName('')
      navigate(`/tour/${id}`)
      onClose()
    } else {
      console.error(
        'cannot create tour: tourModelId, name or userId are empty',
        tourModelId && name && userId
      )
    }
  }

  const tourModelsOptions = useMemo(() => {
    const modelsAr = _.sortBy(
      _.filter(tourModels, tm => tm.enabled),
      tm => tm.createdAt
    )
    return _.map(modelsAr, (m: TourModelT) => {
      const opt: OptionT = {
        value: m.id,
        label: getAddress(m.address)
      }
      return opt
    })
  }, [tourModels])

  const onSelectTourModel = (opt: SingleValue<OptionT>) => {
    if (opt) {
      console.log('onSelectTourModel', opt)
      setTourModelId(opt.value)
    } else {
      setTourModelId(null)
    }
  }

  const optionsValue = useMemo(() => {
    return _.find(tourModelsOptions, opt => opt.value === tourModelId)
  }, [tourModelsOptions, tourModelId])

  const renderModelSelector = () => {
    if (!modelSelectorHidden) {
      return (
        <FormControl isRequired>
          <FormLabel htmlFor='matterportModelId'>Property</FormLabel>
          <Select<OptionT, false, GroupBase<OptionT>>
            // size='sm'
            closeMenuOnSelect
            selectedOptionStyle='check'
            hideSelectedOptions={false}
            value={optionsValue}
            options={tourModelsOptions}
            placeholder='Choose property'
            onChange={v => onSelectTourModel(v)}
            chakraStyles={{
              menu: provided => ({ ...provided, zIndex: 1000 })
            }}
          />
        </FormControl>
      )
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size='xl'
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader borderBottomWidth={1}>New Design</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} pt={8}>
            <FormControl isRequired>
              <FormLabel htmlFor='tourName'>Design Name</FormLabel>
              <Input
                value={name}
                placeholder='Name'
                onChange={e => setName(e.target.value)}
              />
            </FormControl>
            {renderModelSelector()}
          </VStack>

          <Flex mb={4} pt={12} justify='flex-end'>
            <Button
              colorScheme={'teal'}
              ml={2}
              disabled={!canCreate()}
              onClick={onCreateClick}
            >
              Create
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default forwardRef(CreateTourModal)
