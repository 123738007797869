import { FC, useRef } from 'react'
import _ from 'lodash'

import { MpSdk } from 'shared/bundle/sdk'
import { orientedBoxType, makeOrientedBox } from 'shared/components/OrientedBox'
import { slotType, makeSlot } from 'shared/components/Slot'
import {
  loadingIndicatorType,
  makeLoadingIndicator
} from 'shared/components/matterport/LoadingIndicator'
import {
  roomShadowType,
  makeRoomShadow
} from 'shared/components/matterport/MRoomShadow'
import {
  centerPointerType,
  makeCenterPointer
} from 'shared/components/matterport/MCenterPointer'
import THREE, { WebGLRenderer } from 'three'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import {
  hemisphereLightType,
  makeHemisphereLight
} from 'shared/components/matterport/MHemisphereLight'
import {
  customPointLightType,
  makeCustomPointLight
} from 'shared/components/matterport/MPointLight'
import {
  makeOutlinePass,
  outlinePassType
} from 'shared/components/matterport/MOutlinePass'
import {
  customSpotLightType,
  makeCustomSpotLight
} from 'shared/components/matterport/MSpotLight'
import {
  customDirectionalLightType,
  makeCustomDirectionalLight
} from 'shared/components/matterport/MDirectionalLight'
import {
  itemHighlightType,
  makeItemHighlight
} from 'shared/components/matterport/MItemHighlight'
import {
  rotateControlType,
  makeRotateControl
} from 'shared/components/matterport/MRotateControl'
import {
  makeMoveButton,
  moveButtonType
} from 'shared/components/matterport/MMoveButton'
import {
  makeModelIntersection,
  modelIntersectionType
} from 'shared/components/matterport/MModelIntersection'
import {
  makeRoomInvironment,
  roomEnvironmentType
} from 'shared/components/matterport/MRoomEnvironment'

type TourProps = {
  modelId: string
  applicationKey: string
  onSdkReady: (sdk: MpSdk, composer?: EffectComposer) => void
  autoPlay?: boolean
  quickStart?: boolean
  hidePresentedBy?: boolean
  hideDollHouse?: boolean
  hideGuidedTour?: boolean
  hideHighlightReels?: boolean
  hideBranding?: boolean
  hideAbout?: boolean
  hideMattertags?: boolean
  hidePins?: boolean
  hide360Views?: boolean
  hideFloorSwitch?: boolean
  disableZoom?: boolean
  ignoreScrollWheel?: boolean
  disablePanToNewHighligt?: boolean
  loopGuidedTour?: boolean
  hideTitle?: boolean
  hideVR?: boolean
  startGuidedTourIn?: number
  hideFloorPlan?: boolean
}

const Tour: FC<TourProps> = ({
  modelId,
  applicationKey,
  onSdkReady,
  autoPlay = true,
  quickStart = true,
  hidePresentedBy = true,
  hideDollHouse = false,
  hideGuidedTour = false,
  hideHighlightReels = false,
  hideBranding = true,
  hideAbout = true,
  hideMattertags = true,
  hidePins = true,
  hide360Views = true,
  hideFloorSwitch = false,
  disableZoom = true,
  ignoreScrollWheel = false,
  disablePanToNewHighligt = false,
  loopGuidedTour = false,
  hideTitle = true,
  hideVR = false,
  startGuidedTourIn = -1,
  hideFloorPlan = false
}: TourProps) => {
  const showcaseRef = useRef<HTMLIFrameElement>(null)

  const onSdkInitialized = async (sdk: MpSdk) => {
    await sdk.Scene.registerComponents([
      { name: orientedBoxType, factory: makeOrientedBox },
      { name: slotType, factory: makeSlot },
      { name: loadingIndicatorType, factory: makeLoadingIndicator },
      { name: roomShadowType, factory: makeRoomShadow },
      { name: centerPointerType, factory: makeCenterPointer },
      { name: hemisphereLightType, factory: makeHemisphereLight },
      { name: customPointLightType, factory: makeCustomPointLight },
      { name: customSpotLightType, factory: makeCustomSpotLight },
      { name: outlinePassType, factory: makeOutlinePass },
      { name: customDirectionalLightType, factory: makeCustomDirectionalLight },
      { name: itemHighlightType, factory: makeItemHighlight },
      { name: rotateControlType, factory: makeRotateControl },
      { name: moveButtonType, factory: makeMoveButton },
      { name: modelIntersectionType, factory: makeModelIntersection },
      { name: roomEnvironmentType, factory: makeRoomInvironment }
    ])

    // await sdk.Mattertag.registerIcon(
    //   'moveIcon',
    //   'https://firebasestorage.googleapis.com/v0/b/upstager-dev.appspot.com/o/upstager_move_cursor2.png?alt=media&token=e9cdc159-bee5-4191-b2c0-f5f6647d28b9'
    // )

    await sdk.Scene.configure(
      (
        renderer: WebGLRenderer,
        three: typeof THREE,
        composer: EffectComposer | null
      ) => {
        console.log('renderer', renderer)
        console.log('composer', composer)
        console.log('three', three)
        console.log('fxaa shader', _.get(three, 'FXAAShader'))
        renderer.shadowMap.enabled = true
        renderer.outputEncoding = three.sRGBEncoding
        renderer.shadowMap.type = three.PCFSoftShadowMap
        renderer.toneMapping = three.ACESFilmicToneMapping
        renderer.toneMappingExposure = 1.6
        // do not turn autoClear, it breaks measurment tool
        // renderer.autoClear = true
        renderer.physicallyCorrectLights = true

        console.log('renderer', renderer)

        if (composer) {
          console.log('three.postprocessing', _.get(three, 'postprocessing'))
          const ShaderPass = _.get(three, 'ShaderPass')
          const effectFXAA = new ShaderPass(_.get(three, 'FXAAShader'))
          const pixelRatio = renderer.getPixelRatio()
          effectFXAA.uniforms.resolution.value.x =
            1 / (window.innerWidth * pixelRatio)
          effectFXAA.uniforms.resolution.value.y =
            1 / (window.innerHeight * pixelRatio)
          console.log('effectFXAA', effectFXAA)
          composer.addPass(effectFXAA)
          console.log('composer after adding fxaa', composer)
        }

        // sdk.Tag.data.subscribe({
        //   onAdded: function (index, item, collection) {
        //     console.log('Tag added to the collection', index, item, collection)
        //     if (item.label !== 'Custom') {
        //       setTimeout(() => sdk.Tag.remove(item.id), 1000)
        //     }
        //   },
        //   onRemoved: function (index, item, collection) {
        //     console.log(
        //       'Tag removed from the collection',
        //       index,
        //       item,
        //       collection
        //     )
        //   },
        //   onUpdated: function (index, item, collection) {
        //     console.log(
        //       'Tag updated in place in the collection',
        //       index,
        //       item,
        //       collection
        //     )
        //   }
        // })
        onSdkReady(sdk, composer || undefined)
      }
    )

    // await Promise.all([
    //   sdk.Scene.register(orientedBoxType, makeOrientedBox),
    //   sdk.Scene.register(slotType, makeSlot),
    //   sdk.Scene.register(loadingIndicatorType, makeLoadingIndicator),
    //   sdk.Scene.register(roomShadowType, makeRoomShadow),
    //   sdk.Scene.register(centerPointerType, makeCenterPointer),
    //   sdk.Scene.register(sceneEnvType, makeSceneEnv),
    //   sdk.Scene.register(hemisphereLightType, makeHemisphereLight)
    // ])
    // onSdkReady(sdk)
  }

  const onLoad = async () => {
    const bundleWindow = _.get(showcaseRef, 'current.contentWindow')
    const mpSDK = bundleWindow.MP_SDK

    try {
      const sdk = (await mpSDK.connect(
        bundleWindow,
        applicationKey,
        '3.5'
      )) as MpSdk
      // console.log('sdk', sdk)

      sdk.App.state.subscribe(function (appState) {
        // app state has changed
        console.log('The current application: ', appState.application)
        console.log('The current phase: ', appState.phase)
        console.log(
          'Loaded at time ',
          appState.phaseTimes[sdk.App.Phase.LOADING]
        )
        console.log(
          'Started at time ',
          appState.phaseTimes[sdk.App.Phase.STARTING]
        )
        if (appState.phase === sdk.App.Phase.PLAYING) {
          // setTimeout(() => onSdkInitialized(sdk), 1000)
          onSdkInitialized(sdk)
        }
      })
    } catch (e) {
      console.error('onLoad', e)
      return null
    }
  }

  const params = {
    play: autoPlay ? '1' : '0',
    ts: startGuidedTourIn,
    qs: quickStart ? '1' : '0',
    brand: hidePresentedBy ? '0' : '1',
    dh: hideDollHouse ? '0' : '1',
    gt: hideGuidedTour ? '0' : '1',
    hr: hideHighlightReels ? '0' : '1',
    mls: hideBranding ? (hideAbout ? '2' : '1') : '0',
    mt: hideMattertags ? '0' : '1',
    pin: hidePins ? '0' : '1',
    portal: hide360Views ? '0' : '1',
    f: hideFloorSwitch ? '0' : '1',
    lang: 'en',
    nozoom: disableZoom ? '1' : '0',
    wh: ignoreScrollWheel ? '0' : '1',
    kb: disablePanToNewHighligt ? '0' : '1',
    lp: loopGuidedTour ? '1' : '0',
    title: hideTitle ? '0' : '1',
    tourcta: '0', // No call to action at the end of a a Guided Tour
    vr: hideVR ? '0' : '1',
    log: '1',
    search: '0',
    fp: hideFloorPlan ? '0' : '1'
  }
  const stringParams = _.map(params, (v, k) => `${k}=${v}`).join('&')

  return (
    <iframe
      ref={showcaseRef}
      title='tour-showcase'
      id='showcase'
      src={`/bundle/showcase.html?m=${modelId}&applicationKey=${applicationKey}&newtags=1&${stringParams}`}
      width='100%'
      height='100%'
      frameBorder='0'
      allowFullScreen
      onLoad={onLoad}
      allow='xr-spatial-tracking'
      onError={msg => console.log('tour error', msg)}
    />
  )
}

export default Tour
