import { FC } from 'react'
import { Flex, Stack, Text } from '@chakra-ui/react'
import numeral from 'numeral'

type Props = {
  total: number
  title?: string
  size?: string
}

const OrderSummary: FC<Props> = ({ total, title = 'Total', size = 'xl' }) => {
  return (
    <Stack spacing='4' rounded='lg' width='full' py={4} px={4}>
      <Flex justify='space-between'>
        <Text fontSize={size} fontWeight='bold'>
          {title}:
        </Text>
        <Text fontSize={size} fontWeight='extrabold'>
          {numeral(total).format('$0,0.00')}
        </Text>
      </Flex>
    </Stack>
  )
}

export default OrderSummary
