import { FC, useState, useMemo } from 'react'
import Catalog from 'shared/components/catalogPanel/Catalog'
import CategoryItems from 'shared/components/catalogPanel/CategoryItems'
import _ from 'lodash'
import { DictT, ItemT, SlotT, SupplierT } from 'shared/types/model'

type Props = {
  closeCatalogClick: () => void
  addToRoom: (itemId: string) => void
  visible: boolean
  selectedItemId: string | null
  openProductCard: (itemId: string) => void
  itemsBySlot: DictT<DictT<ItemT>>
  slots: DictT<SlotT>
  suppliers?: DictT<SupplierT>
  isEditor?: boolean
  toSuppliers?: () => void
}

const CatalogPanel: FC<Props> = ({
  closeCatalogClick,
  addToRoom,
  visible,
  selectedItemId,
  openProductCard,
  itemsBySlot,
  slots,
  suppliers,
  isEditor,
  toSuppliers
}) => {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null)

  const onCloseCatalogClick = () => {
    setSelectedCategory(null)
    closeCatalogClick()
  }

  const items = useMemo(() => {
    if (selectedCategory) {
      return _.get(itemsBySlot, selectedCategory, {})
    } else {
      return {}
    }
  }, [selectedCategory, itemsBySlot])

  // console.log('itemsBySlot', itemsBySlot, 'isEditor', isEditor)

  if (!visible) {
    return null
  } else if (selectedCategory) {
    console.log('Category Items')
    return (
      <CategoryItems
        toCatalog={() => setSelectedCategory(null)}
        closeCatalog={onCloseCatalogClick}
        addToRoom={addToRoom}
        selectedItemId={selectedItemId}
        openProductCard={openProductCard}
        slot={slots[selectedCategory]}
        items={items}
        suppliers={suppliers}
      />
    )
  } else {
    console.log('return Catalog')
    return (
      <Catalog
        goBack={closeCatalogClick}
        selectCategory={(slotId: string) => setSelectedCategory(slotId)}
        selectedItemId={selectedItemId}
        itemsBySlot={itemsBySlot}
        slots={slots}
        isEditor={isEditor}
        toSuppliers={toSuppliers}
      />
    )
  }
}

export default CatalogPanel
