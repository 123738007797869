export const RECEIVE_TOURS = 'RECEIVE_TOURS'
export const CLEAR = 'CLEAR'
export const LOGOUT = 'LOGOUT'
export const RECEIVE_AUTH_DATA = 'RECEIVE_AUTH_DATA'
export const RECEIVE_SLOTS = 'RECEIVE_SLOTS'
export const RECEIVE_ITEMS = 'RECEIVE_ITEMS'
export const RECEIVE_USER = 'RECEIVE_USER'
export const RECEIVE_TOUR_MODELS = 'RECEIVE_TOUR_MODELS'
export const RECEIVE_MATTERTAG = 'RECEIVE_MATTERTAG'
export const RECEIVE_ORDERS = 'RECEIVE_ORDERS'
export const RECEIVE_SUPPLIERS = 'RECEIVE_SUPPLIERS'
export const RECEIVE_SETTINGS = 'RECEIVE_SETTINGS'
export const RECEIVE_PARTNERS = 'RECEIVE_PARTNERS'
