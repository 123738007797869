import * as types from 'model/actionTypes'
import { ItemT, DictT } from 'shared/types/model'

const initialState: DictT<ItemT> | null = null

export default function (
  state = initialState,
  action: { type: string; items: DictT<ItemT> }
) {
  switch (action.type) {
    case types.RECEIVE_ITEMS:
      return {
        ...state,
        ...action.items
      }
    case types.CLEAR:
    case types.LOGOUT:
      return initialState
    default:
      return state
  }
}
